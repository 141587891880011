<div *ngIf="{ value: isActive$ | async } as isActive" class="gridster-item-content context-menu__container">
  <!-- trigger icon as replacement for real menu before the real menu is activated-->
  <button #matMenuTrigger type="button" class="wt-button wt-button--icon"
    [ngClass]="{'wt-hidden': isActive.value === true}" (click)="activateAction.next()">
    <mat-icon class="mat-icon-small" fontIcon="more_vert"></mat-icon>
  </button>

  <!-- real menu only which is rendered after activation (for memory and performance as there can be many menu items in a list)-->
  <ng-container *ngIf="isActive.value">
    <ng-container *ngIf="menuItems$ | async as menuItems">
      <button #matMenuTrigger type="button" class="wt-button wt-button--icon" [matMenuTriggerFor]="panel.menu">
        <mat-icon class="mat-icon-small"
          fontIcon="more_vert"></mat-icon>
      </button>

      <wt-menu-panel #panel [items]="menuItems" [context]="context" (itemClick)="onItemClick($event)"></wt-menu-panel>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="autoOpenMenuAfterActivation$ | async"></ng-container>