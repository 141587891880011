import { Inject, Injectable } from '@angular/core';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { type GuardJSONCompatible, PreferencesMap } from '@vwd/microfrontend-core';
import { Observable, from } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { InfrontUtil } from '@infront/sdk';
import { structuresAreEqual } from '../util/equality';
import { StorageService, type TenantSettingsStorageData, TenantSettingsStorageKeys, type UserSettingsStorageData, UserSettingsStorageKeys } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  private userSettingAction = new LastValueSubject<UserSettingsStorageData>();

  // wrap set to keep set and get available from the same service/wrapper
  setValue<K extends keyof UserSettingsStorageData>(key: K, value: GuardJSONCompatible<UserSettingsStorageData[K]> | undefined): Observable<void> {
    if (value == undefined) {
      return from(this.userSettingsStorage.delete(key));
    } else {
      return from(this.userSettingsStorage.set(key, value));
    }
  }

  // clients should not have to set up watch and dispose, get and future change detection through watch will be combined in one observable
  getValue$<K extends keyof UserSettingsStorageData>(key: K): Observable<UserSettingsStorageData[K]> { // @TODO add Observable<undefined> to ReturnType
    return this.userSettingAction.pipe(
      map((userStorage) => userStorage[key]),
      distinctUntilChanged((prev, next) => structuresAreEqual(prev, next))
    );
  }

  getValues$<K extends keyof UserSettingsStorageData>(keys: K[]): Observable<{ [Key in K]: UserSettingsStorageData[Key] }> {
    return this.userSettingAction.pipe(
      map((userStorage) => {
        const keyValues = {} as { [Key in K]: UserSettingsStorageData[Key] };
        keys.forEach((key) => {
          keyValues[key] = userStorage[key];
        });
        return keyValues;
      }),
      distinctUntilChanged((prev, next) => structuresAreEqual(prev, next))
    );
  }

  // FIXME: can be undefined
  // snapshot so clients don't need to subscribe when they just want to check the latest
  getValue<K extends keyof UserSettingsStorageData>(key: K): UserSettingsStorageData[K] {
    return this.userSettingsStorage.get(key)!;
  }

  // all snapshots need to go to storage
  getAllCurrentValues(): UserSettingsStorageData {
    const values = {} as UserSettingsStorageData;
    for (const key of UserSettingsStorageKeys) {
      let value = this.userSettingsStorage.get(key);
      // fall back to tenant default settings
      if (value == undefined && TenantSettingsStorageKeys.includes(key as string as keyof TenantSettingsStorageData)) {
        value = this.tenantSettingsStorage.get(key);
        if (typeof value === 'object') {
          value = InfrontUtil.deepCopy(value) as typeof value;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (values as any)[key] = value;
    }
    return Object.freeze(values);
  }

  private readonly userSettingsStorage = this.storageService.getUserSettingsStorage() as PreferencesMap<UserSettingsStorageData>;
  private readonly tenantSettingsStorage = this.storageService.getTenantStorage() as PreferencesMap<TenantSettingsStorageData>;


  constructor(@Inject(StorageService) private storageService: StorageService) {
    from(this.userSettingsStorage.ready())
      .pipe(
        tap(() => {

          this.buildSettingsAndEmit();

          this.userSettingsStorage.watch(() => {
            queueMicrotask(() => this.buildSettingsAndEmit());
          });
          this.tenantSettingsStorage.watch(() => {
            queueMicrotask(() => this.buildSettingsAndEmit());
          });

        })
      )
      .subscribe();
  }

  private buildSettingsAndEmit(): void {
    const newSettings = this.getAllCurrentValues();
    if (!structuresAreEqual(newSettings, this.userSettingAction.value)) {
      this.userSettingAction.next(newSettings);
    }
  }
}
