import { Component, HostListener } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LogService } from '@vwd/ngx-logging';

import { AdminDialogComponent } from '../../admin/admin-dialog/admin-dialog.component';
import { mockupState } from '../../dashboard/mockup';
import { RemoteStorageService } from '../../services/remote-storage.service';
import { StateService } from '../../services/state.service';
import { TenantSettingsService } from '../../services/tenant-settings.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'wt-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  // IWT-1051 magic keys combination for admin-panel
  @HostListener('document:keydown.control.shift.q') toggleAdmin() {
    this.showAdmin = !this.showAdmin;
    this.userStorage.setValue('showAdminPanel', this.showAdmin || undefined);
  }

  showAdminDialogOption = false;
  showAdmin = false;
  showMainMenu = false;
  folderEditingEnabled$ = this.userStorage.getValue$('enableDashboardFolderEditing');

  private readonly logger = this.logService.openLogger('layout/header');

  constructor(
    private readonly logService: LogService,
    private remoteStorageService: RemoteStorageService,
    public stateService: StateService,
    public dialog: MatDialog,
    public userStorage: UserSettingsService,
    public tenantStorage: TenantSettingsService,
  ) {
    this.showAdmin = this.userStorage.getValue('showAdminPanel') ?? false;
    this.showAdminDialogOption = this.tenantStorage.canEdit();
  }

  loadDashboards(): void {
    this.remoteStorageService.getDashboards().subscribe();
  }

  mockupsAsState(): void {
    this.stateService.setState(mockupState, 'User');
  }

  templateAsState(): void {
    // this.stateService.setState(templateState, 'User');
  }

  openAdminDialog(): void {
    this.dialog.open(AdminDialogComponent, {
      disableClose: false,
      width: '800px',
      height: '600px',
      autoFocus: false,
    });
  }

  disableFolderEditing(): void {
    this.userStorage.setValue('enableDashboardFolderEditing', false);
  }

  // canUndo$ = this.stateService.canUndo$;

  // canRedo$ = this.stateService.canRedo$;
}
