import { InfrontSDK } from '@infront/sdk';
import type { AdditionalSymbolFields } from '../../widgets/lists/observe-symbols.service';

export enum DisplayStatusMap {
  'undefined' = 0,
  'suspended' = 1,
  'attention' = 2,
  'after-market' = 3,
  'pre-market' = 4,
  'auction' = 5,
  'open' = 6,
  'closed' = 7,
}
// DisplayStatus = 'undefined' | 'suspended' | 'attention' | 'after-market' | 'pre-market' | 'auction' | 'open' | 'close'
export type DisplayStatus = keyof typeof DisplayStatusMap;

export const fields = [
  InfrontSDK.SymbolField.PreSymbolStatusText,
  InfrontSDK.SymbolField.DisplaySymbolStatus,
  InfrontSDK.SymbolField.DisplaySymbolStatusCode,
];

export type StatusSymbol = {
  PreSymbolStatusText: string;
  DisplaySymbolStatus: string;
  DisplaySymbolStatusCode: number;
} & AdditionalSymbolFields;



