import { ChangeDetectionStrategy, Component, EventEmitter, Input, type OnDestroy, type OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LastValueSubject } from '@infront/ngx-dashboards-fx/utils';
import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { GLOBAL_WL_TRANSLATE_KEYS, WatchlistService } from '../../../services/watchlist.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../util/components/confirm-dialog/confirm-dialog.component';
import { NewDialogComponent } from '../../../util/components/new-dialog/new-dialog.component';
import { NewWatchlistDialogComponent } from '../../../util/components/new-dialog/new-watchlist-dialog/new-watchlist-dialog.component';
import { structuresAreEqual } from '../../../util/equality';
import { translator } from '../../../util/locale';
import { filterUndefined } from '../../../util/rxjs';
import { newUniqueName } from '../../../util/utils';
import { DropdownComponent } from '../dropdown.component';
import { type DropdownFolder, isDropdownFolder } from '../dropdown.model';
import { DROPDOWN_WATCHLIST_CLASS_MOD, type WatchlistDropdownItem } from './dropdown-watchlist.model';

@Component({
  selector: 'wt-dropdown-watchlist',
  templateUrl: './dropdown-watchlist.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownWatchlistComponent implements OnInit, OnDestroy {
  private readonly watchlistService = inject(WatchlistService);
  private readonly dialog = inject(MatDialog);

  private readonly xlat = translator();

  @Output() readonly menuClosed = new EventEmitter<void>();
  @Output() readonly selectedWatchlistChange = new EventEmitter<InfrontSDK.Watchlist | undefined>();

  @Input() set selectedWatchlistId(watchlistId: string | undefined) {
    this.trySelectedWatchlistIdAction.next(watchlistId);
  }
  get selectedWatchlistId(): string | undefined {
    // don't use in template, not change detection safe
    // refer to subscribing to selectedWatchlist$ directly
    return this.selectedWatchlistAction.getValue()?.id;
  }
  @Input() dropdownLabel?: string;
  @Input() matDropdownIcon?: string = 'visibility';
  @Input() matSubMenuIcon?: string = 'domain';
  @Input() selectFirstWatchlistOnUndefined = true;

  readonly watchlists$ = combineLatest([this.watchlistService.watchlistsByProviders$, this.watchlistService.watchlists$]).pipe(
    debounceTime(0),
    map(([wlsByProvider, { watchlists }]) => {
      const userLists = wlsByProvider[0]?.map((watchlist) => this.getWatchlistAsDropdownItem(watchlist));
      const providerLists = Object.entries(wlsByProvider)
        .filter(([provider]) => provider !== '0')
        .map(([provider, wls]) => {
          const providerName: string = wls[0]?.providerName ?? `${this.xlat('GLOBAL.WATCHLIST.PROVIDER')} ${provider}`;
          const providerWatchlistItem: DropdownFolder<WatchlistDropdownItem> = {
            folderLabel: providerName,
            translateFolderLabel: false,
            subItems: wls.map((wl) => this.getWatchlistAsDropdownItem(wl)),
          };
          return providerWatchlistItem;
        });
      return {
        watchlistDropdownItems: [...(providerLists ?? []), ...(userLists ?? [])], // We want to show provider lists first, then user lists
        rawWatchlists: watchlists, // raw & flat watchlists required for some functions
      };
    }),
    shareReplay(1),
  );

  /**
   * trySelectedWatchlist acts as a guard, it does not necessarily contain the truly selected Watchlist
   * If you need to access the selectedWatchlist please refer to using selectedWatchlist$ or selectedWatchlistDropdownItem$
   * @internal
   */
  readonly trySelectedWatchlistIdAction = new LastValueSubject<string | undefined>();

  readonly selectedWatchlistAction = new LastValueSubject<InfrontSDK.Watchlist | undefined>();
  readonly selectedWatchlist$ = this.selectedWatchlistAction.pipe(
    distinctUntilChanged(structuresAreEqual),
    shareReplay(1),
  );

  // Only use in template - this isn't guaranteed to be the true title of the selectedWatchlist
  // Required for provider watchlists to add the providerName in front
  readonly selectedWatchlistTemplateTitle$ = this.selectedWatchlist$.pipe(
    map((selectedWatchlist) => {
      if (!selectedWatchlist) {
        return undefined;
      }
      return selectedWatchlist.providerName
        ? `${selectedWatchlist.providerName} - ${selectedWatchlist.title}`
        : selectedWatchlist.title;
    }),
  );

  readonly selectedWatchlistDropdownItem$ = combineLatest([this.selectedWatchlist$, this.watchlists$]).pipe(
    debounceTime(0),
    map(([selectedWatchlist, { watchlistDropdownItems }]) => {
      const id = selectedWatchlist?.id;
      let selectedWatchlistDropdownItem: WatchlistDropdownItem | undefined;
      if (id && watchlistDropdownItems.length) {
        for (const item of watchlistDropdownItems) {
          if (isDropdownFolder(item)) {
            selectedWatchlistDropdownItem = item.subItems?.find((subWl) => !isDropdownFolder(subWl) && subWl.id === id);
          } else if (item.id === id) {
            selectedWatchlistDropdownItem = item;
          }

          if (selectedWatchlistDropdownItem != undefined) {
            break;
          }
        }
      }
      return selectedWatchlistDropdownItem;
    }),
    distinctUntilChanged(structuresAreEqual), // Id checking isn't enough since the watchlist can be updated (symbols etc)
    tap((selectedWatchlist) => this.selectedWatchlistChange.emit(selectedWatchlist)),
    shareReplay(1),
  );

  @ViewChild(DropdownComponent) readonly dropdownComponent!: DropdownComponent<WatchlistDropdownItem>;

  readonly matMenuScopeClass = DROPDOWN_WATCHLIST_CLASS_MOD;
  disableUserActions = false;

  private readonly ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    if (this.selectedWatchlistAction.getValue() === undefined) {
      this.selectedWatchlistAction.next(undefined);
    }

    this.watchlistService.getSelectedWatchlist$(this.trySelectedWatchlistIdAction, this.selectFirstWatchlistOnUndefined).pipe(
      tap((nextSelectedWatchlist) => this.selectedWatchlistAction.next(nextSelectedWatchlist)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.trySelectedWatchlistIdAction.complete();
    this.selectedWatchlistAction.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getWatchlistAsDropdownItem(watchlist: InfrontSDK.Watchlist): WatchlistDropdownItem {
    return { ...InfrontUtil.deepCopy(watchlist) as InfrontSDK.Watchlist, isInEdit: false };
  }

  getNewDefaultWatchlistTitle(watchlists: InfrontSDK.Watchlist[]): string {
    const defaultWlTitleKey = GLOBAL_WL_TRANSLATE_KEYS.DEFAULT_TITLE;
    const defaultName = this.xlat(defaultWlTitleKey);
    return newUniqueName(
      defaultName,
      watchlists.map((wl) => wl.title)
    );
  }

  hasWriteAccess(watchlist: WatchlistDropdownItem): Observable<boolean> {
    if (!watchlist.provider) return of(true);
    return this.watchlistService.providerAccess$(watchlist.provider, 'write');
  }

  onNewWatchlist(event: MouseEvent, watchlists: InfrontSDK.Watchlist[]): void {
    this.dropdownComponent.stopPropagation(event);

    if (this.disableUserActions) {
      return;
    }

    const title = this.getNewDefaultWatchlistTitle(watchlists);

    const openNewWatchlistDialog = (data: InfrontSDK.WatchlistProviderAccess) => {
      const providerReadAccess = Object.entries(data.write).map(([provider, data]) => ({
        provider: parseInt(provider),
        providerName: data.name,
      }));

      return this.dialog.open(NewWatchlistDialogComponent, {
        width: '300px',
        data: { input: { title, provider: undefined, providerReadAccess } },
      }).afterClosed().pipe(
        take(1),
        filterUndefined(),
        map((result) => result as { title: string; provider: number | undefined })
      );
    };

    const saveNewWatchlistIfNeeded = (dialogData: { title: string; provider: number | undefined }) => {
      if (!dialogData.title) {
        return EMPTY;
      }
      return this.watchlistService.saveWatchlist$(dialogData.title, dialogData.provider).pipe(
        switchMap((hasSavedNewWatchlist: boolean) => (hasSavedNewWatchlist ? of(dialogData.title) : EMPTY))
      );
    };

    const selectNewWatchlist = (title: string) => {
      return this.watchlistService.watchlists$.pipe(
        take(1),
        tap(({ watchlists }) => {
          const newWatchlist = watchlists?.find((wl) => wl.title === title);
          if (newWatchlist) {
            this.trySelectedWatchlistIdAction.next(newWatchlist?.id);
          }
        })
      );
    };

    this.watchlistService.watchlistProviderAccess$.pipe(
      take(1),
      switchMap((data) => openNewWatchlistDialog(data)),
      switchMap((dialogData) => saveNewWatchlistIfNeeded(dialogData)),
      switchMap((title) => selectNewWatchlist(title)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }


  onDeleteWatchlist(event: MouseEvent, watchlist: InfrontSDK.Watchlist): void {
    this.dropdownComponent.stopPropagation(event);

    if (this.disableUserActions || !watchlist?.title) {
      return;
    }

    const title = this.xlat('DROPDOWN_WATCHLIST.DELETE_WATCHLIST_DIALOG.TITLE');
    const message = this.xlat('DROPDOWN_WATCHLIST.DELETE_WATCHLIST_DIALOG.DIALOG', { watchlistTitle: watchlist.title });
    const dialogData = new ConfirmDialogModel(title, message);

    this.dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data: dialogData })
      .afterClosed()
      .pipe(
        take(1),
        filter((result) => !!result),
        tap((shouldDelete) => {
          if (shouldDelete) {
            this.watchlistService.deleteWatchlist$(watchlist.title, watchlist.provider).pipe(take(1)).subscribe();
          }
        }),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  onStartEdit(event: MouseEvent, watchlist: WatchlistDropdownItem): void {
    this.dropdownComponent.stopPropagation(event);

    this.dialog.open(NewDialogComponent, {
      width: '300px',
      data: {
        input: watchlist.title,
        icon: 'edit',
        title: this.xlat('DROPDOWN_WATCHLIST.EDIT_WATCHLIST_DIALOG.TITLE'),
        closeLabel: this.xlat('DROPDOWN_WATCHLIST.EDIT_WATCHLIST_DIALOG.CANCEL'),
        submitLabel: this.xlat('DROPDOWN_WATCHLIST.EDIT_WATCHLIST_DIALOG.SUBMIT'),
      },
    })
      .afterClosed()
      .pipe(
        take(1),
        filter((result) => !!result),
        tap((newTitle: string) => {
          if (newTitle != watchlist.title) {
            this.onEditName(newTitle, watchlist);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      ).subscribe();
  }

  onEditName(newTitle: string, watchlist: WatchlistDropdownItem): void {
    if (this.disableUserActions) {
      return;
    }

    this.disableUserActions = true;

    this.watchlistService
      .renameWatchlist$(watchlist.title, newTitle, watchlist.provider)
      .pipe(
        take(1),
        finalize(() => {
          this.disableUserActions = false;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  onMenuClosed(): void {
    this.disableUserActions = false;
    this.menuClosed.emit();
  }
}
