import { InfrontSDK, InfrontUtil } from '@infront/sdk';
import type { ResourceService } from '@vwd/ngx-i18n/translate';
import type { SymbolDataItem } from '../shared/models/symbol-data.model';
import type { Instrument } from '../state-model/window.model';
import { getInstrumentFromSymbolLike } from './sdk';

export interface SymbolDataExt {
  Feed: number;
  Ticker: string;
  symbol: InfrontSDK.SymbolData;
}

export interface InstrumentLike {
  feed?: number;
  ticker?: string;
  Feed?: number;
  Ticker?: string;
  get?: (field: InfrontSDK.SymbolField) => unknown;
}

export type ErrorBase = InfrontSDK.ErrorBase<{ feed?: number; ticker?: string }>;
export interface SymbolDataSubHeader {
  section: string;
  value: string;
  translation?: string;
}

export type TableSymbolData = (InfrontSDK.SymbolData | SymbolDataSubHeader) & {
  index?: string;
  memberOf?: string;
  noAccess?: boolean;
  noFlag?: boolean;
  noClickable?: boolean;
  isTradable?: boolean;
};

export const getTickerFromData = (data: unknown): string | undefined => {
  if (data == undefined) {
    return;
  }
  return (data as SymbolDataExt).Ticker
    ?? (data as InfrontSDK.SymbolId).ticker
    ?? (data as InfrontSDK.SymbolData).get?.(InfrontSDK.SymbolField.Ticker);
};

export const getFeedFromData = (data: unknown): number | undefined => {
  if (data == undefined) {
    return;
  }
  return (data as SymbolDataExt).Feed
    ?? (data as InfrontSDK.SymbolId).feed
    ?? (data as InfrontSDK.SymbolData).get?.(InfrontSDK.SymbolField.Feed);
};

export function isSameInstrument(i1: InstrumentLike, i2: InstrumentLike): boolean {
  const i1Feed = getFeedFromData(i1);
  const i2Feed = getFeedFromData(i2);
  const i1Ticker = getTickerFromData(i1);
  const i2Ticker = getTickerFromData(i2);
  return !!i1Feed && !!i2Ticker && i1Feed === i2Feed && i1Ticker === i2Ticker;
}

export function instrumentLikeToInstrument(instrumentLike: InstrumentLike): Instrument | undefined {
  const feed = instrumentLike.feed ?? instrumentLike.Feed;
  const ticker = instrumentLike.ticker ?? instrumentLike.Ticker;
  if (feed == undefined || ticker == undefined) {
    return;
  }
  return { feed, ticker };
}

export const isSubHeader = (symbolData: TableSymbolData | undefined): symbolData is SymbolDataSubHeader & { index?: string } => {
  return !!((symbolData as SymbolDataSubHeader)?.section && (symbolData as SymbolDataSubHeader)?.value);
};

export const isNoAccess = (symbolData: TableSymbolData | undefined): boolean => {
  return !!symbolData?.noAccess;
};

export const isSymbolData = (symbolData: unknown): symbolData is InfrontSDK.SymbolData => {
  // assume that objects being able to get a Ticker are real or fake symbolData, both are valid!
  return (
    // eslint-disable-next-line @typescript-eslint/unbound-method
    InfrontUtil.isFunction((symbolData as InfrontSDK.SymbolData)?.get) && !!(symbolData as InfrontSDK.SymbolData).get(InfrontSDK.SymbolField.Ticker)
  );
};

export const isObservableSymbolData = (symbolData: TableSymbolData): boolean => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  return InfrontUtil.isFunction((symbolData as InfrontSDK.SymbolData)?.observe);
};

export const createInstrumentFromSymbolData = (symbolData: InfrontSDK.SymbolData | undefined): Instrument | undefined => {
  if (!symbolData || !isSymbolData(symbolData)) {
    return;
  }

  return getInstrumentFromSymbolLike(symbolData);
};

export function createSymbolDataFromSymbol(symbolId: InfrontSDK.SymbolData, index?: number): SymbolDataItem {
  return {
    ...symbolId,
    index: index != undefined ? `${index}~${InfrontUtil.makeUUID()}` : '',
    isError: false,
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    get: symbolId.get?.bind(symbolId),
    getConverted: symbolId.getConverted?.bind(symbolId),
    observe: symbolId.observe?.bind(symbolId),
    notifyOnContent: symbolId.notifyOnContent?.bind(symbolId),
    inspect: symbolId.inspect?.bind(symbolId),
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */
  };
}

export const createSymbolDataFromError = (error: ErrorBase): InfrontSDK.SymbolData => {
  return {
    get: (field: InfrontSDK.SymbolField) => {
      switch (field) {
        case InfrontSDK.SymbolField.Feed:
          return error.parameters?.feed;
        case InfrontSDK.SymbolField.Ticker:
          return error.parameters?.ticker;
        case InfrontSDK.SymbolField.PreDisplayTicker:
          return error.parameters?.ticker;
        default:
          return undefined;
      }
    },
    getConverted: (field: InfrontSDK.SymbolField, targetCurrency: unknown, valueCallback: unknown) => { },
    noAccess: true,
  } as unknown as InfrontSDK.SymbolData;
};

export const hasSymbolDataFromError = (error: ErrorBase, symbolDataFromError: InfrontSDK.SymbolData[]): boolean => {
  return symbolDataFromError.some(
    (d) => error.parameters?.feed === d.get(InfrontSDK.SymbolField.Feed) && error.parameters?.ticker === d.get(InfrontSDK.SymbolField.Ticker)
  );
};

export const getUnderlyingSymbolData = (
  feed: number,
  ticker: string,
  underlyingSymbolDataList: InfrontSDK.SymbolData[]
): InfrontSDK.SymbolData | undefined => {
  if (!feed || !ticker) {
    return;
  }

  if (underlyingSymbolDataList?.length) {
    const underlyingSymbolData = underlyingSymbolDataList.find(
      (s) => s?.get?.(InfrontSDK.SymbolField.Feed) === feed && s?.get?.(InfrontSDK.SymbolField.Ticker) === ticker
    );
    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (InfrontUtil.isFunction(underlyingSymbolData?.get)) {
      return underlyingSymbolData;
    }
  }
  return createSymbolDataFromError({ parameters: { feed, ticker } } as ErrorBase);
};

export const getSymbolTickerTranslation = (translate: ResourceService, feed: number, ticker: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  if (!InfrontUtil.isFunction(translate?.get) || !ticker || !feed) {
    return;
  }

  // kind of Frontend PreDisplayTicker logic, requires Feed and Ticker (not PreDisplayTicker)
  const translation = translate.get(`GLOBAL.SYMBOL.PRE_DISPLAY_TICKER.${feed}.${ticker}`) as string;
  return translation !== ticker ? translation : undefined;
};

export const canAddWindowForClassification = (
  addWindowKey: 'Orderbook' | 'Trades' | 'News',
  classification: InfrontSDK.SymbolClassification
): boolean => {
  if (addWindowKey === 'Orderbook') {
    return ![InfrontSDK.SymbolClassification.Fund, InfrontSDK.SymbolClassification.Index].includes(classification);
  }
  if (addWindowKey === 'Trades') {
    return ![InfrontSDK.SymbolClassification.Fund].includes(classification);
  }
  if (addWindowKey === 'News') {
    return [InfrontSDK.SymbolClassification.Stock, InfrontSDK.SymbolClassification.ETF].includes(classification);
  }
  return true;
};

export const addToErrorSymbols = (error: InfrontSDK.ErrorBase<{ feed?: number; ticker?: string }>, symbolDataFromError: InfrontSDK.SymbolData[]) => {
  return !hasSymbolDataFromError(error, symbolDataFromError) ? [...symbolDataFromError, createSymbolDataFromError(error)] : symbolDataFromError;
};

export const getDecimals = (data: Partial<SymbolDataItem>): number => (
  data?.symbol?.get?.(InfrontSDK.SymbolField.Decimals)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ?? (data as any)?.Decimals as number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ?? (data as any)?.decimals as number
  ?? 2 // per default format like twoDecimal!
);

export const ADV_PERCENT_HIGHLIGHT_LEVEL = 150;


export function isHighlightByADVpercent(adv: number | undefined, accVolume: number | undefined): boolean {
  const advPct = getAdvPercent(adv, accVolume);
  return !!advPct && (advPct > ADV_PERCENT_HIGHLIGHT_LEVEL);
}


export function getAdvPercent(adv: number | undefined, accVolume: number | undefined): number | undefined {
  if (!accVolume || !adv) {
    return undefined;
  }
  const advPct = advPercent(+accVolume, +adv) ?? 0;
  return advPct;
}

export function advPercent(accVol: number, rtAdv: number) { return rtAdv ? (accVol / rtAdv) * 100 : undefined; }
