import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import type { CellClassParams, IRowNode, SortDirection, ValueGetterParams, ITooltipParams } from 'ag-grid-community';

import type { Column } from '../../shared/grid/columns.model';
import { isSymbolData } from '../../util/symbol';
import { ListsCategories, ListsColumnDefs, ListsColumns } from '../lists/lists.columns';
import { na } from '../../shared/grid/grid.model';
import type { ResourceService } from '@vwd/ngx-i18n/translate';
import type { FormattingService } from '@vwd/ngx-i18n';
import { tickerClickableCellClass } from '../../shared/grid/column-registry';

interface PortfolioOrderGridData {
  symbol?: {
    tradingSymbol?: InfrontSDK.Trading.PortfolioItem;
  };
}

type ValidUntilValue = Date | string | undefined;

export const TradingPrefix = 'Trading~';

const buySellColorBadge = (params: { value: string | undefined }) => {
  if (params.value == undefined) {
    return ['grid__cell'];
  }
  if (params.value === 'SELL') {
    return ['grid__cell', 'ag-cell--portfolio-orders-sell'];
  }
  return params.value === 'BUY'
    ? ['grid__cell', 'ag-cell--portfolio-orders-buy']
    : ['grid__cell'];
};

// The TradingOrdersColumns key and colId must all start with "tradingOrder"-prefix,
// as else they can not be distinguished from other grid columns, which never must
// have the "tradingOrder"-prefix!
// We need all trading-orders columns to be identifiable as TradingOrdersColumns
// for e.g. click-event handlers on the grid-columns!
export const TradingOrdersColumns = {
  tradingOrdersCountryFlagTicker: {
    colId: 'tradingOrdersCountryFlagTicker',
    headerName: 'Symbol',
    headerTooltip: 'Symbol',
    cellRenderer: 'countryFlagTickerCellComponent',
    cellClass: tickerClickableCellClass,
    width: 75,
  },
  tradingOrdersFullName: {
    colId: 'tradingOrdersFullName',
    headerName: 'Name',
    tooltipValueGetter: (params: ITooltipParams<string, string>) => params.value,
    field: `${TradingPrefix}${InfrontSDK.SymbolField.FullName}` as InfrontSDK.TradingField,
    width: 161,
  },
  tradingOrdersMarket: {
    colId: 'tradingOrdersMarket',
    headerName: 'Market',
    headerTooltip: 'Infront market for the instrument.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Market}` as InfrontSDK.TradingField,
    width: 60,
  },
  tradingOrdersBuyOrSell: {
    colId: 'tradingOrdersBuyOrSell',
    headerName: 'Direction',
    headerTooltip: `Indicates either if it's a BUY or SELL order.`,
    field: `${TradingPrefix}${InfrontSDK.TradingField.BuyOrSell}` as InfrontSDK.TradingField,
    // by using cellRenderer the value gets rendered inside <span></span> as required to display color badge!
    // when using params.valueFormatted, a cellFormatter needs to be provided, too!
    cellRenderer: (params: { value: string }) => params.value ?? '-',
    cellClass: (params: CellClassParams<string | undefined>) => [...buySellColorBadge(params), 'grid__cell--centered'],
    headerClass: 'text-center',
    width: 60,
  },
  tradingOrdersVolume: {
    colId: 'tradingOrdersVolume',
    headerName: 'Volume',
    headerTooltip: 'Volume of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Volume}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 65,
  },
  // FIXME correct data to be found
  // FIXME streaming will only work, if we have a single, streamed field: "DisplayPrice"
  tradingOrdersPrice: {
    colId: 'tradingOrdersPrice',
    headerName: 'Price',
    headerTooltip: 'Price of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AlgoParams}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams<PortfolioOrderGridData>) => priceValue(params),
    valueFormatter: 'sdkDecimals',
    type: 'rightAligned',
    width: 65,
  },
  tradingOrdersAveragePrice: {
    colId: 'tradingOrdersAveragePrice',
    headerName: 'Avg. price',
    headerTooltip: 'Average price.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AveragePrice}` as InfrontSDK.TradingField,
    valueFormatter: 'sdkDecimals',
    type: 'rightAligned',
    width: 85,
  },
  tradingOrdersValueWithCurrency: {
    colId: 'tradingOrdersValueWithCurrency',
    headerName: 'Value',
    headerTooltip: 'Value of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Price}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => valueWithCurrencyValue(params),
    valueFormatter: 'twoDecimalsCurrency',
    type: 'rightAligned',
    width: 100,
  },
  // Keep for now: currency in separate column would be sortable!
  // tradingOrdersCurrency: {
  //   colId: 'tradingOrdersCurrency',
  //   headerName: 'Ccy',
  //   headerTooltip: 'Trading Currency',
  //   field: `${TradingPrefix}${InfrontSDK.TradingField.Currency}` as InfrontSDK.TradingField,
  //   width: 50,
  //   type: 'rightAligned',
  // },
  tradingOrdersFilled: {
    colId: 'tradingOrdersFilled',
    headerName: 'Filled',
    headerTooltip: 'Show filled on the order. Example 50/200 filled means you have a partial fill of 50 of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.VolumeFilled}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => filledValue(params),
    // by using cellRenderer the value gets rendered inside <span></span> as required to display color badge!
    // when using params.valueFormatted, a cellFormatter needs to be provided, too!
    cellRenderer: (params: { value: string }) => params.value ?? '-',
    cellClass: ['grid__cell', 'grid__cell--centered', 'ag-cell--portfolio-orders-filled'],
    headerClass: 'text-center',
    width: 60,
  },
  tradingOrdersOrderStatus: {
    colId: 'tradingOrdersOrderStatus',
    headerName: 'Order Status',
    headerTooltip: 'Order status of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.OrderStatus}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 85,
  },
  tradingOrdersOrderType: {
    colId: 'tradingOrdersOrderType',
    headerName: 'Order Type',
    headerTooltip: 'Order type of the order.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.DisplayOrderType}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => tradingFieldValue<string>(params, InfrontSDK.TradingField.DisplayOrderType),
    tooltipValueGetter: (params: ITooltipParams) => tradingFieldValue<string>(params, InfrontSDK.TradingField.DisplayOrderType),
    type: 'rightAligned',
    width: 115,
  },
  tradingOrdersValidUntil: {
    colId: 'tradingOrdersValidUntil',
    headerName: 'Valid Until',
    headerTooltip: `Computed field, it shows the DATE if it's a regular order, but we also show GTC(good to cancel) or NEXT AUCTION.`,
    field: `${TradingPrefix}${InfrontSDK.TradingField.ValidDate}` as InfrontSDK.TradingField,
    valueGetter: validUntilValue,
    type: 'rightAligned',
    width: 85,
    comparator: validUntilComparator,
  },
  tradingOrdersOrderId: {
    colId: 'tradingOrdersOrderId',
    headerName: 'Order Id',
    headerTooltip: 'Identifier of the order. Higher number means more newly created.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.OrderId}` as InfrontSDK.TradingField,
    type: 'rightAligned',
    width: 85,
  },
  tradingOrdersPlaced: {
    colId: 'tradingOrdersPlaced',
    headerName: 'Placed',
    headerTooltip: 'Time when the order got placed.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.Time}` as InfrontSDK.TradingField,
    tooltipValueGetter: (params: ITooltipParams) => placedValue(params),
    valueGetter: (params: ValueGetterParams) => placedValue(params),
    valueFormatter: 'timeOrDateFormatter',
    type: 'rightAligned',
    width: 80,
    // not the best place to set the default sorting for the trading-order tables,
    // but as only ID strings are stored in the actual *DefaultColumns definitions, there is not much of a choice!
    sort: 'desc' as SortDirection,
    comparator: (valueA: Date, valueB: Date, nodeA: IRowNode, nodeB: IRowNode, isDescending: boolean) => dateComparator(valueA, valueB, isDescending),
  },
  // FIXME streaming will only work, if we have a single, streamed field: "DisplayTriggerPrice"
  tradingOrdersTriggerPrice: {
    colId: 'tradingOrdersTriggerPrice',
    headerName: 'Trigger price',
    headerTooltip: 'Trigger price.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AlgoParams}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => triggerPriceValue(params),
    valueFormatter: 'sdkDecimals',
    type: 'rightAligned',
    width: 100,
  },
  // FIXME streaming will only work, if we have a single, streamed field: "DisplayTrailLimit"
  tradingOrdersTrailLimit: {
    colId: 'tradingOrdersTrailLimit',
    headerName: 'Trail limit',
    headerTooltip: 'Trail limit.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AlgoParams}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => trailLimitValue(params),
    valueFormatter: 'sdkDecimalsCurrencyFlex',
    type: 'rightAligned',
    width: 85,
  },
  // FIXME streaming will only work, if we have a single, streamed field: "DisplayDeviation"
  tradingOrdersDeviation: {
    colId: 'tradingOrdersDeviation',
    headerName: 'Deviation',
    headerTooltip: 'Deviation.',
    field: `${TradingPrefix}${InfrontSDK.TradingField.AlgoParams}` as InfrontSDK.TradingField,
    valueGetter: (params: ValueGetterParams) => tradingDeviationValue(params),
    valueFormatter: 'sdkDecimalsCurrencyFlex',
    type: 'rightAligned',
    width: 85,
  },
  tradingOrdersLastValid: {
    ...ListsColumnDefs.lastValid,
    colId: 'tradingOrdersLastValid',
    width: 85,
  }
} satisfies { [key: string]: Column; };

function tradingFieldValue<T>(
  params: (
    ValueGetterParams<{ symbol?: { tradingSymbol?: InfrontSDK.Trading.PortfolioItem; } } | undefined>
    | ITooltipParams<{ symbol?: { tradingSymbol?: InfrontSDK.Trading.PortfolioItem; } } | undefined>
  ),
  tradingField: InfrontSDK.TradingField,
  fallback: T | string = '.'
): T | string | undefined | null {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  let value: T | undefined;
  if (isSymbolData(tradingSymbol)) {
    value = tradingSymbol.get(tradingField) as T | undefined;
  }
  return value ?? fallback;
}

/**
 * Returns the PortfolioOrderItem specific CustomField label property (Validity)
 * Should not be outsourced outside of PortfolioOrder relevant files.
 */
function getValidityStr(tradingSymbol: InfrontSDK.Trading.PortfolioItem): string | undefined {
  const customFields = tradingSymbol.get(InfrontSDK.TradingField.CustomFields) as Infront.CustomTagData[];
  let validityStr: string | undefined;
  if (typeof customFields === 'object') {
    validityStr = Object.values(customFields)?.find((customField) => customField.type === Infront.ParamType.ValidSession)?.label;
  }
  return validityStr;
}

function validUntilValue(params: ValueGetterParams<PortfolioOrderGridData>): string {
  // TradingSymbol
  const tradingSymbol = params.data?.symbol?.tradingSymbol;
  if (!tradingSymbol) {
    return na;
  }

  // ValidityString
  const validityStr: string | undefined = getValidityStr(tradingSymbol);
  if (validityStr) {
    return validityStr;
  }

  // ValidityDate or Today
  const validityDate = tradingSymbol.get(InfrontSDK.TradingField.ValidDate) as string;
  if (!validityDate) {
    return na;
  }
  const date: Date = new Date(validityDate);
  if (!InfrontUtil.isValidDate(date)) {
    return na;
  } else if (InfrontUtil.isToday(date)) {
    return (params.context as { translate?: ResourceService; }).translate?.get('GLOBAL.TODAY') as string;
  }
  return (params.context as { format?: FormattingService; }).format?.formatDateTime(date, 'shortestDate') as string;
}

const placedValue = (params: ValueGetterParams<PortfolioOrderGridData> | ITooltipParams<PortfolioOrderGridData>): Date | undefined => {
  const tradingSymbol = params.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    // WTKAPI-492 InfrontSDK.TradingField.Time provides correct order date and time.
    // Before we had to use createDateTime(entryDate, entryTime) with
    // entryTime = tradingSymbol.get(InfrontSDK.TradingField.Time) as Date
    // entryDate = tradingSymbol.get(InfrontSDK.TradingField.Date) as Date
    return tradingSymbol.get(InfrontSDK.TradingField.Time) as Date;
  }
  return undefined;
};

const priceValue = (params: ValueGetterParams<PortfolioOrderGridData>): number | undefined => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    const orderType = tradingSymbol?.get(InfrontSDK.TradingField.OrderType) as InfrontSDK.Trading.OrderType;
    const price = (
      // need to separate Strategy/StopLoss from other orders, as they have defined Ask/Bid 0.0001 for unknown reasons!
      [InfrontSDK.Trading.OrderType.StopLoss, InfrontSDK.Trading.OrderType.Strategy].includes(orderType)
        ? (
          tradingSymbol.get(InfrontSDK.TradingField.OrderPrice)
          || algoGet(tradingSymbol, '-ForeTgtPx')
        )
        : (
          tradingSymbol.get(InfrontSDK.TradingField.Bid)
          ?? tradingSymbol.get(InfrontSDK.TradingField.Ask)
        )
    ) as number;
    return price;
  }
  return undefined;
};

const valueWithCurrencyValue = (params: ValueGetterParams): string | undefined => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    const currency = tradingSymbol.get(InfrontSDK.TradingField.Currency) as string;
    // FIXME test with bid, ask, but field price does not update them, so no solution!
    const price = (tradingSymbol.get(InfrontSDK.TradingField.Bid) ?? tradingSymbol.get(InfrontSDK.TradingField.Ask)) as number;
    const volume = tradingSymbol.get(InfrontSDK.TradingField.Volume) as number;
    return (price == undefined) || (volume == undefined)
      // eslint-disable-next-line no-sparse-arrays
      ? JSON.stringify([, currency]) // NOSONAR comma is not unexpected!
      : JSON.stringify([price * volume, currency]);
  }
  return undefined;
};

const filledValue = (params: ValueGetterParams): string => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${tradingSymbol.get(InfrontSDK.TradingField.VolumeFilled)}/${tradingSymbol.get(InfrontSDK.TradingField.Volume)}`;
  }
  return '-';
};

const triggerPriceValue = (params: ValueGetterParams): number | undefined => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    // TODO triggerprice missing for fieldName TriggerPrice in customTags! (order type 15)
    const triggerPrice = (
      algoGet(tradingSymbol, 'TriggerPx')
      ?? algoGet(tradingSymbol, '-TriggerAtPx')
    ) as number;
    return triggerPrice;
  }
  return undefined;
};

const trailLimitValue = (params: ValueGetterParams): string | undefined => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    // TODO: tradingSymbol.get(InfrontSDK.TradingField.TrailLimit) not working as expected
    const algoId = tradingSymbol.get(InfrontSDK.TradingField.AlgoId) as string;
    if (algoId === 'InfrontCondor_TrailTick') {
      const trailLimit = algoGet(tradingSymbol, 'TrailLimit') as number;
      // eslint-disable-next-line no-sparse-arrays
      return JSON.stringify(trailLimit != undefined ? [trailLimit, 'ticks'] : [, 'ticks']); // NOSONAR
    }
    if (algoId === 'InfrontCondor_TrailPct') {
      const trailLimit = algoGet(tradingSymbol, 'TrailLimit') as number;
      // eslint-disable-next-line no-sparse-arrays
      return JSON.stringify(trailLimit != undefined ? [trailLimit, '%'] : [, '%']); // NOSONAR
    }
  }
  return undefined;
};

const tradingDeviationValue = (params: ValueGetterParams): string | undefined => {
  const tradingSymbol = params?.data?.symbol?.tradingSymbol as InfrontSDK.Trading.PortfolioItem;
  if (isSymbolData(tradingSymbol)) {
    // TODO: tradingSymbol.get(InfrontSDK.TradingField.Deviation) not working as expected
    const algoId = tradingSymbol.get(InfrontSDK.TradingField.AlgoId) as string;
    if (algoId === 'InfrontCondor_TrailTick') {
      const ticksFromTrigger = algoGet(tradingSymbol, 'TicksFromTrigger');
      // eslint-disable-next-line no-sparse-arrays
      return JSON.stringify(ticksFromTrigger != undefined ? [ticksFromTrigger, 'ticks'] : [, 'ticks']); // NOSONAR
    }
    if (algoId === 'InfrontCondor_TrailPct') {
      const pctFromTrigger = algoGet(tradingSymbol, 'PctFromTrigger');
      // eslint-disable-next-line no-sparse-arrays
      return JSON.stringify(pctFromTrigger != undefined ? [pctFromTrigger, '%'] : [, '%']); // NOSONAR
    }
  }
  return undefined;
};

const algoGet = (tradingSymbol: InfrontSDK.Trading.PortfolioItem, key: string): string | number | Date | undefined => {
  /* eslint-disable */
  const algoParams = tradingSymbol?.get(InfrontSDK.TradingField.AlgoParams);
  return algoParams?.find((param: any) => param.id === key)?.get() as string | number | Date | undefined;
  /* eslint-enable */
};

const dateComparator = (valueA: Date | undefined, valueB: Date | undefined, isDescending: boolean) => {
  // isDescending not required as for now
  if ((valueA == undefined) && (valueB != undefined)) { return -1; }
  if ((valueA != undefined) && (valueB == undefined)) { return 1; }
  if ((valueA == undefined) && (valueB == undefined)) { return 0; }
  return valueA!.getTime() - valueB!.getTime();
};

function validUntilComparator(
  valueA: ValidUntilValue,
  valueB: ValidUntilValue,
  nodeA: IRowNode<PortfolioOrderGridData>,
  nodeB: IRowNode<PortfolioOrderGridData>,
  isDescending: boolean
): number {
  /* WIP IWT-1622 */
  valueA = getValidUntilComparatorValue(valueA, nodeA);
  valueB = getValidUntilComparatorValue(valueB, nodeB);
  return dateComparator(valueA, valueB, isDescending);
}

function getValidUntilComparatorValue(value: ValidUntilValue, node: IRowNode<PortfolioOrderGridData>): Date | undefined {
  if (!(value instanceof Date)) {
    value = node.data?.symbol?.tradingSymbol?.get(InfrontSDK.TradingField.ValidDate) as Date;
    if (value != undefined && !(value instanceof Date)) {
      value = new Date(value);
    }
  }

  if (!InfrontUtil.isValidDate(value)) {
    value = undefined;
  }

  return value;
}

const StandardTradingCols: Column[] = Object.values(TradingOrdersColumns);
const StandardAllCols = [...StandardTradingCols, ...ListsColumns];

const TradingCategory = {
  name: 'Trading',
  columns: StandardTradingCols,
};
export const TradingCategories = [TradingCategory, ...ListsCategories];

// Default columns used e.g. by Active, Algo, Inactive
export const PortfolioOrdersDefaultColumns = [...StandardAllCols];
export const SelectedPortfolioOrdersDefaultColumns: { colId: string; sort?: Column['sort']; }[] = [
  TradingOrdersColumns.tradingOrdersCountryFlagTicker,
  TradingOrdersColumns.tradingOrdersFullName,
  TradingOrdersColumns.tradingOrdersMarket,
  TradingOrdersColumns.tradingOrdersBuyOrSell,
  TradingOrdersColumns.tradingOrdersVolume,
  TradingOrdersColumns.tradingOrdersPrice,
  TradingOrdersColumns.tradingOrdersValueWithCurrency,
  TradingOrdersColumns.tradingOrdersFilled,
  TradingOrdersColumns.tradingOrdersLastValid,
  TradingOrdersColumns.tradingOrdersOrderStatus,
  TradingOrdersColumns.tradingOrdersOrderType,
  TradingOrdersColumns.tradingOrdersValidUntil,
  TradingOrdersColumns.tradingOrdersPlaced,
  // TradingOrdersColumns.tradingOrdersOrderId, // good for chronological sorting and debug
].map((col: Column) => ({
  colId: col.colId, sort: col.sort,
}));

export const PortfolioOrdersStopLossDefaultColumns = [...StandardAllCols];
export const SelectedPortfolioOrdersStopLossDefaultColumns: { colId: string; sort?: Column['sort']; }[] = [
  TradingOrdersColumns.tradingOrdersCountryFlagTicker,
  TradingOrdersColumns.tradingOrdersFullName,
  TradingOrdersColumns.tradingOrdersMarket,
  TradingOrdersColumns.tradingOrdersBuyOrSell,
  TradingOrdersColumns.tradingOrdersVolume,
  TradingOrdersColumns.tradingOrdersPrice,
  TradingOrdersColumns.tradingOrdersTriggerPrice,
  TradingOrdersColumns.tradingOrdersFilled,
  TradingOrdersColumns.tradingOrdersTrailLimit,
  TradingOrdersColumns.tradingOrdersDeviation,
  TradingOrdersColumns.tradingOrdersOrderType,
  TradingOrdersColumns.tradingOrdersValidUntil,
  TradingOrdersColumns.tradingOrdersPlaced,
  // TradingOrdersColumns.tradingOrdersOrderId, // good for chronological sorting and debug
].map((col: Column) => ({
  colId: col.colId, sort: col.sort,
}));

export const PortfolioOrdersExecutedDefaultColumns = [...StandardAllCols];
export const SelectedPortfolioOrdersExecutedDefaultColumns: { colId: string; sort?: Column['sort']; }[] = [
  TradingOrdersColumns.tradingOrdersCountryFlagTicker,
  TradingOrdersColumns.tradingOrdersFullName,
  TradingOrdersColumns.tradingOrdersMarket,
  TradingOrdersColumns.tradingOrdersBuyOrSell,
  TradingOrdersColumns.tradingOrdersVolume,
  TradingOrdersColumns.tradingOrdersPrice,
  TradingOrdersColumns.tradingOrdersValueWithCurrency,
  TradingOrdersColumns.tradingOrdersFilled,
  TradingOrdersColumns.tradingOrdersLastValid,
  TradingOrdersColumns.tradingOrdersAveragePrice,
  TradingOrdersColumns.tradingOrdersOrderType,
  TradingOrdersColumns.tradingOrdersValidUntil,
  TradingOrdersColumns.tradingOrdersPlaced,
  // TradingOrdersColumns.tradingOrdersOrderId, // good for chronological sorting and debug
].map((col: Column) => ({
  colId: col.colId, sort: col.sort,
}));

