import { type AfterViewInit, type OnChanges, type OnInit, type SimpleChanges, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

import { translator } from '../../util/locale';
import { setOverlayPanelClass } from '../../util/mat-menu';
import { type DropdownEntry, type DropdownItem, isDropdownFolder, isDropdownItem } from './dropdown.model';

const DROPDOWN_CLASS = 'wt-dropdown-menu';

@Component({
  selector: 'wt-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent<TItem extends object> implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('matMenu') matMenu!: MatMenu;
  @ViewChild('matMenu', { read: ElementRef }) matMenuElementRef!: ElementRef;

  @Input() matMenuScopeClass: string | undefined;
  @Input() height: number | undefined;
  @Input({ required: true }) items!: DropdownEntry<TItem>[];
  @Input() dropdownLabel?: string;
  @Input() translateDropdownLabel?: boolean;
  @Input() matDropdownIcon?: string;
  @Input() matSubMenuIcon?: string;
  @Input() isSvgIcon?: boolean;
  @Input() useSelectedItemLabel?: boolean;
  @Input() noDropdownLabelPlaceholder = 'NA';
  @Input() translateNoDropdownLabelPlaceholder?: boolean;
  @Input() noItemLabelPlaceholder = 'NA';
  @Input() translateNoItemLabelPlaceholder?: boolean;
  @Input() itemLabelProperty: keyof DropdownItem<TItem> = 'itemLabel';

  @Input() selectedItem: DropdownItem<TItem> | undefined;

  @Input() dropdownTemplate?: TemplateRef<unknown>;
  @Input() preItemTemplate?: TemplateRef<unknown>;
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() postItemTemplate?: TemplateRef<unknown>;
  @Input() dropdownArrow? = false;
  @Output() menuClosed = new EventEmitter<void>();
  @Output() menuOpened = new EventEmitter<void>();
  @Output() itemSelected = new EventEmitter<DropdownItem<TItem>>();

  isDropdownFolder = isDropdownFolder;
  isDropdownItem = isDropdownItem;

  private readonly xlat = translator();

  dropDownLabel = this.noDropdownLabelPlaceholder;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDropDownLabel();
  }

  ngOnInit(): void {
    this.updateDropDownLabel();
  }

  private updateDropDownLabel(): void {
    const placeholderLabel = this.translateNoItemLabelPlaceholder ? this.xlat(this.noItemLabelPlaceholder) : this.noItemLabelPlaceholder;
    this.dropDownLabel = placeholderLabel;
    if (this.selectedItem?.translateDropdownLabel) {
      this.dropDownLabel = this.selectedItem?.dropdownLabel ? this.xlat(this.selectedItem.dropdownLabel) : placeholderLabel;
    }
    else if (this.selectedItem?.translateItemLabel) {
      this.dropDownLabel = this.selectedItem?.itemLabel ? this.xlat(this.selectedItem.itemLabel) : placeholderLabel;
    }
    else if (this.dropdownLabel && this.translateDropdownLabel) {
      this.dropDownLabel = this.xlat(this.dropdownLabel);
    }
    else if (this.dropdownLabel) {
      this.dropDownLabel = this.dropdownLabel;
    }
  }

  ngAfterViewInit(): void {
    setOverlayPanelClass(this.matMenu, DROPDOWN_CLASS, this.matMenuScopeClass);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

}
