/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { InfrontSDK } from '@infront/sdk';
import { Observable, ReplaySubject, Subscriber, filter, switchMap } from 'rxjs';

import type { SymbolDataItem, SymbolWithTradingSymbol } from '../../shared/models/symbol-data.model';
import { TradingPrefix } from './portfolio-positions.columns';

export type DataValue = Record<string, unknown>; // lack any better internal (or external) representation for mixed data from resolved tradingSymbol and regular symbol
export type AdditionalSymbolFields = { index: string; symbol: InfrontSDK.SymbolData };


export interface ObserveTradingParams<T extends InfrontSDK.SymbolField | InfrontSDK.TradingField> {
  symbols: SymbolWithTradingSymbol[], // list of instruments to observe changes for
  fields: T[], // list of fields per instrument to observe changes for
  uuid: string, // unique id for the entity that is observing, usually widget or grid, needed to unbind when destroyed
  initialUpdate?: InfrontSDK.InitialUpdate, // default InfrontSDK.InitialUpdate.Always,
}

@Injectable({
  providedIn: 'root',
})
export class ObserveTradingSymbolsService {
  private readonly observeAction = new ReplaySubject<{ uuid: string }>(1);

  /**
   * Makes an ag grid applyTransactionAsync (recommended for performance and available with throttling) update from an InfrontSDK symbol.observe callback
   * This has to be done on the row level so whenever we update a field we have to also update all other fields on the same row, we take the old field data from our own cache
  */
  observeSymbols$<T extends InfrontSDK.SymbolField>(params: ObserveTradingParams<T>) {
    const { uuid } = params;
    this.observeAction.next({ uuid });
    return this.observeAction.pipe(
      filter((item) => item.uuid === params.uuid),
      switchMap(() => this.observeSymbolsInner$<T>(params)));
  }


  private observers: { [uuid: string]: { [key: string]: { unbinder?: () => void } } } = {};

  private newRow<T extends InfrontSDK.SymbolField | InfrontSDK.TradingField>(symbol: SymbolWithTradingSymbol, fields: T[]) {
    const item = fields.reduce(
      (acc, field) => {
        acc[field] = field.startsWith(TradingPrefix)
          ? symbol.tradingSymbol.get(field.replace(TradingPrefix, '') as InfrontSDK.TradingField)
          : symbol.get?.(field as InfrontSDK.SymbolField);
        return acc;
      },
      { index: (symbol as SymbolDataItem).index, symbol } as any
    );
    return item;
  }

  private readonly addUnbinderToObserver = (uuid: string, key: string, unbinder: () => void) => {
    if (!this.observers[uuid][key]) {
      this.observers[uuid][key] = {};
    }
    this.observers[uuid][key].unbinder = unbinder;
  };

  private readonly observeSymbolsInner$ = <T extends InfrontSDK.SymbolField | InfrontSDK.TradingField>(
    { symbols: list, fields, uuid, initialUpdate = InfrontSDK.InitialUpdate.Always }: ObserveTradingParams<T>
  ): Observable<DataValue[]> =>
    new Observable((obs: Subscriber<DataValue[]>) => {
      if (!this.observers[uuid]) {
        this.observers[uuid] = {};
      }
      list.forEach((symbol: SymbolWithTradingSymbol) => {
        fields.forEach((obsField) => {
          if (!symbol.symbolId?.ticker || !symbol.symbolId?.feed) {
            return; // if theres no id we don't observe changes
          }
          const orderId = symbol.tradingSymbol.get(InfrontSDK.TradingField.OrderId) as string | undefined;
          const positionsId = `${symbol.symbolId?.ticker}~${symbol.symbolId?.feed}`;
          const id = orderId ?? positionsId;
          const key = `${id}~${obsField}`;

          if (this.observers[uuid][key]) {
            return;
          }
          const refreshRow = () => {
            const item = this.newRow(symbol, fields);
            obs.next(item);
          };
          if (obsField.startsWith(TradingPrefix)) {
            const realTradingFieldName = obsField.replace(TradingPrefix, '') as InfrontSDK.TradingField;
            const tradingSymbolObserver = symbol.tradingSymbol?.observe(realTradingFieldName, () => refreshRow());
            this.addUnbinderToObserver(uuid, key, tradingSymbolObserver);
            return;
          }
          const symbolObserver = symbol.observe(
            obsField as InfrontSDK.SymbolField,
            () => refreshRow(),
            undefined,
            initialUpdate
          );
          this.addUnbinderToObserver(uuid, key, symbolObserver);
        });
      });

      return () => {
        Object.values(this.observers[uuid])
          .map((item) => item.unbinder)
          .forEach((unbind) => unbind?.());
        delete this.observers[uuid];
      };
    });

  //resolves a set of InfrontSDK symbols to a rowlist of datavalues ASAP without storing anything in the cache, used for first load
  readonly symbolGetValueList$ = <T extends InfrontSDK.SymbolField>(
    symbols: SymbolWithTradingSymbol[],
    fields: T[]
  ): Observable<({ [K in T]: InfrontSDK.SymbolFieldTypeBase[K] } & AdditionalSymbolFields)[]> =>
    new Observable((obs: Subscriber<any[]>) => {
      const data: DataValue[] = [];
      symbols.forEach((symbol: SymbolWithTradingSymbol) => {
        const item = this.newRow(symbol, fields);
        data.push(item);
      });
      obs.next(data);
    });
}
