<ng-container *ngIf="dashboard$ | async as dashboard">
  <ng-container *ngIf="window$ | async as window">
    <ng-container *ngIf="widget$ | async as widget">
      <div *ngIf="vm$ | async as vm"
        class="widget-title-bar__title gridster-item-content"
        [class.wt-draggable]="!dashboard.locked && window.dragEnabled !== false">
        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle">
          <ng-container [ngSwitch]="window.settings.calendarType">
            <ng-container *ngSwitchCase="'Instrument'">
              <div class="wt-flex-column wt-flex-shrink">
                <mat-icon
                  class="widget-title-bar-icon widget-title-bar-icon--clickable widget-title-bar-icon--search gridster-item-content"
                  fontIcon="search"
                  (click)="onSearchClick(window)">
                </mat-icon>
              </div>

              <div class="wt-flex-column">
                <ng-container *ngIf="vm.symbolInfo as symbolInfo; else selectInstrument">
                  <button
                    class="wt-button wt-button--label widget-title-bar-label widget-title-bar-label--clickable gridster-item-content"
                    (click)="onSearchClick(window)">
                    {{ symbolInfo.preDisplayTicker }}
                    {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (window.name || "NA")| translate }}
                  </button>
                  <span *ngIf="symbolInfo.feedIsHidden; else clickableFeed" class="widget-title-bar-feed">
                    {{ symbolInfo.feedExchange }}
                  </span>

                  <ng-template #clickableFeed>
                    <button class="wt-button wt-button--label widget-title-bar-feed widget-title-bar-feed--clickable"
                      (click)="onSpawnMarketWindow($event)">
                      {{ symbolInfo.feedExchange }}
                    </button>
                  </ng-template>
                </ng-container>

                <ng-template #selectInstrument>
                  <button class="wt-button" (click)="onSearchClick(window)">
                    {{ "DASHBOARD_WINDOW.HEADER.CALENDAR_HEADER.SELECT_INSTRUMENT" | translate }}
                  </button>
                </ng-template>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'Watchlist'">
              <div class="wt-flex-column">
                <wt-dropdown-watchlist
                  [dropdownLabel]="(vm.selectedWatchlist?.title || ('DASHBOARD_WINDOW.HEADER.CALENDAR_HEADER.NO_WATCHLIST' | translate))
                + ' - '
                + (('DASHBOARD_WINDOW.HEADER.WINDOW_LABEL.' + (window.name || 'NA')) | translate)"
                  [selectedWatchlistId]="window.settings.selectedWatchlist"
                  (selectedWatchlistChange)="onWatchlistSelected($event)"></wt-dropdown-watchlist>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'Portfolio'">
              <ng-container *ngIf="selectedPortfolio$ | async ; else loggedOut">
                <div class="wt-flex-column">
                  <wt-dropdown-portfolio
                    [selectedPortfolio]="selectedPortfolio$ | async"
                    [matDropdownIcon]="undefined"></wt-dropdown-portfolio>
                  {{ (('DASHBOARD_WINDOW.HEADER.WINDOW_LABEL.' + (window.name || 'NA')) | translate) }}
                </div>
              </ng-container>

              <ng-template #loggedOut>
                <div class="wt-flex-column wt-flex-shrink wt-dropdown-portfolio-icon--account-balance">
                  <mat-icon class="mat-icon-small" fontIcon="account_balance" />
                </div>

                <div class="wt-flex-column">
                  {{ (('DASHBOARD_WINDOW.HEADER.WINDOW_LABEL.' + (window.name || 'NA')) | translate) }}
                </div>
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="'Country'">
              <div class="wt-flex-column">
                <wt-mat-dropdown-country
                  [countryDropdownItems]="(countryDropdownItems$ | async) ?? []"
                  (selectCountryItem)="onCountryItemSelected($event)"></wt-mat-dropdown-country>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div class="wt-flex-column wt-flex-shrink">
                <mat-icon fontIcon="error_outlined"></mat-icon>
              </div>

              <div class="wt-flex-column">
                <span>
                  {{ "DASHBOARD_WINDOW.HEADER.CALENDAR_HEADER.INVALID_SOURCE" | translate }}
                  - {{ "DASHBOARD_WINDOW.HEADER.WINDOW_LABEL." + (window.name || "NA") | translate }}
                </span>
              </div>
            </ng-container>
          </ng-container>

          <div class="wt-flex-column wt-flex-shrink">
            <div class="widget-title-bar__buttons gridster-item-content">
              <ul class="wt-list-inline">
                <li>
                  <wt-link-channel-menu
                    [window]="window"
                    [dashboard]="dashboard"></wt-link-channel-menu>
                </li>
                <li>
                  <wt-context-menu class="gridster-item-content"
                    [context]="{ widget: widget, dashboard: dashboard }"></wt-context-menu>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>