import type { ContextMenuItem } from '../models/context-menu.model';

export const menuItems = [
  {
    closeOnClick: true,
    id: 'Settings',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.SETTINGS',
    icon: 'settings',
  },
  // todo: put reset optional dialogs here for now according to Lasse, should be moved inside settings dialog later
  {
    closeOnClick: true,
    id: 'ResetOptionalDialogs',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_OPTIONAL_DIALOGS',
    icon: 'refresh',
  },
  // @CHECK IWT-1674 if should be done or not!
  // {
  //   closeOnClick: false,
  //   id: 'ResetSettings', // ResetOptionalDialogs
  //   title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_SETTINGS', // MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_OPTIONAL_DIALOGS
  //   icon: 'refresh',
  //   subItems: [
  //     {
  //       closeOnClick: true,
  //       id: 'ResetOptionalDialogs',
  //       title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_OPTIONAL_DIALOGS',
  //       icon: 'refresh',
  //     },
  //     {
  //       closeOnClick: true,
  //       id: 'ResetInstrumentPageCharts',
  //       title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.RESET_INSTRUMENT_PAGE_CHARTS',
  //       icon: 'show_chart'
  //     }
  //   ]
  // },
  {
    closeOnClick: true,
    id: 'MyProfile',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.MY_PROFILE',
    icon: 'account_box',
  },
  {
    closeOnClick: true,
    id: 'Support',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.SUPPORT',
    icon: 'call',
  },
  {
    closeOnClick: true,
    id: 'InstallPwa',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.INSTALL_PWA',
    icon: 'install_desktop',
  },
  //Log out from trading
  {
    closeOnClick: true,
    id: 'LogoutFromTrading',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.LOGOUT_FROM_TRADING',
    icon: 'logout',
  },
  {
    closeOnClick: true,
    id: 'Logout',
    title: 'MAIN_CONTEXT_MENU_COMPONENT.ITEMS.LOGOUT',
    icon: 'logout',
  },
] as const satisfies ContextMenuItem[];

type RecursiveMainContextItem<T extends { id: string; }> = T extends { subItems: ContextMenuItem[]; } ? RecursiveMainContextItem<T['subItems'][number]> : T['id'];
export type MainContextItem = RecursiveMainContextItem<typeof menuItems[number]>;
