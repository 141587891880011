import { Injectable, inject } from '@angular/core';
import { Infront, InfrontSDK } from '@infront/sdk';
import { LogService } from '@vwd/ngx-logging';
import type { CellClickedEvent } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { LinkChannelService } from '../../dashboard-window/ui/link-channel-menu/link-channel.service';
import { InstrumentDashboardService } from '../../dashboard/instrument-dashboard.service';
import { TradingOrderEntryService } from '../../services/trading-order-entry.service';
import { UserSettingsService } from '../../services/user-settings.service';
import type { Widget } from '../../state-model/widget.model';
import { getFeedFromData, getTickerFromData } from '../../util/symbol';
import { TradingOrdersColumns } from '../../widgets/portfolio-orders/portfolio-orders.columns';
import { GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT } from '../models/settings.model';
import type { SymbolDataItem, SymbolWithTradingSymbol } from '../models/symbol-data.model';

export const NO_MODIFY_ORDER_CATEGORY = ['Deleted', 'Executed', 'ExecutedOrDeletedExecuted'];

const ignoreList = ['contextMenu'];
const ignoreClassList = ['ag-icon-grip', 'ag-drag-handle', 'ag-row-drag'];
const openInstrumentDashboard = ['ticker', 'countryFlagTicker', 'tradingOrdersCountryFlagTicker'];
const openOrderEntry = ['lastValid', 'listsBid', 'listsAsk', 'bid', 'ask', 'tradingOrdersLastValid'];

@Injectable({
  providedIn: 'root',
})
export class GridService {
  private readonly logger = inject(LogService).openLogger('services/grid');
  private readonly instrumentDashboardService = inject(InstrumentDashboardService);
  private readonly tradingOrderEntryService = inject(TradingOrderEntryService);
  private readonly linkChannelService = inject(LinkChannelService);

  readonly requestGridCellRefresh$ = new Subject<boolean>();

  // behaviour defined in IWT-911, IWT-1395
  handleGridCellClick(event: CellClickedEvent, widget?: Widget): void {
    // ignore shift and ctrl clicks - those should only create row selection events
    if ((event.event as PointerEvent).shiftKey || (event.event as PointerEvent).ctrlKey) {
      return;
    }
    const data = event.data as unknown;
    const context = event.context as { forceOpenInstrumentDashboard?: boolean, userSettingsService: UserSettingsService };

    // click on context-menu should be ignored here
    if (ignoreList.includes(event?.colDef?.colId as string)) {
      this.logger.debug('handleGridCellClick: do nothing, as colId is in the ignoreList!', event?.colDef?.colId, ignoreList);
      return;
    }

    if (ignoreClassList.some(className => (event.event?.target as HTMLElement)?.classList.contains(className))) {
      return;
    }

    // if allowed, click on ticker should open instrument-dashboard, proper `context` is required!
    const canOpenInstrumentDashboard = !!context?.forceOpenInstrumentDashboard
      || (context?.userSettingsService?.getValue('enableGridOpenInstrumentDashboard') ?? GRID_OPEN_INSTRUMENT_DASHBOARD_DEFAULT);
    if (canOpenInstrumentDashboard && openInstrumentDashboard.includes(event?.colDef?.colId as string)) {
      const ticker = getTickerFromData(data);
      const feed = getFeedFromData(data);
      if (ticker != undefined && feed != undefined) {
        this.logger.debug('handleGridCellClick: open instrument-dashboard', { ticker, feed });
        this.instrumentDashboardService.addInstrumentDashboardAsync({ ticker, feed });
        return;
      }
      this.logger.debug('handleGridCellClick: invalid ticker or feed, cannot open instrument-dashboard!', { data, ticker, feed });
    }

    // click on prices (last, bid, ask) should open order-entry, but only if trading is available!
    if (openOrderEntry.includes(event?.colDef?.colId as string)) {
      // relay on `isTradable` property, which has to be extra set for each symbol when getting the data!
      const isTradable = (data as { symbol: SymbolDataItem }).symbol?.isTradable;
      if (!isTradable) {
        this.logger.debug('handleGridCellClick: symbol not tradable!', { data });
        return;
      }
      // for opening the order-entry overlay, an instrument (`feed`, `ticker`) is required!
      const ticker = getTickerFromData(data);
      const feed = getFeedFromData(data);
      if ((ticker == undefined) || (feed == undefined)) {
        this.logger.debug('handleGridCellClick: invalid ticker or feed, cannot open order-entry!', { data, ticker, feed });
        return;
      }

      const instrument = new Infront.Instrument(feed, ticker);
      const initialPrice = event.value as number;
      this.logger.debug('handleGridCellClick: open order-entry (create mode) with pre-set instrument and initial price', { instrument, initialPrice });
      this.tradingOrderEntryService.openOrderEntry({ instrument, initialPrice });
    }

    // IWT-1185 click on a not-yet-handled trading-orders column shall open modify.
    if (Object.keys(TradingOrdersColumns).includes(event?.colDef?.colId as string)) {
      if (NO_MODIFY_ORDER_CATEGORY.includes(event.context?.widget?.orderCategory as string)) {
        return;
      }

      if (event?.context?.tradingState?.isConnected && event.data) {
        const symbol = event.data.symbol as SymbolWithTradingSymbol;
        const modifyOrderIdStr = symbol?.tradingSymbol.get(InfrontSDK.TradingField.OrderId) as string | undefined;
        const modifyPortfolio = event.context?.tradingState?.activePortfolio?.name as string;
        if ((modifyOrderIdStr != undefined) && (modifyPortfolio != undefined)) {
          const modifyOrderId = +modifyOrderIdStr;
          this.logger.debug('handleGridCellClick: open order-entry (modify mode) for orderId and portfolio', { modifyOrderId, modifyPortfolio });
          this.tradingOrderEntryService.openOrderEntry({ modifyOrderId, modifyPortfolio });
        }
        this.logger.debug('handleGridCellClick: invalid orderId or portfolio, cannot open order-entry (modify mode!', { data, modifyOrderIdStr, modifyPortfolio });
      }
    }

    if (widget) {
      // click on any other column, should send link message, but only if window (of widget) is linked!
      this.linkChannelService
        .getLinkChannel$(widget)
        .pipe(
          take(1),
          filter((linkChannel) => !!linkChannel && linkChannel !== 'None'),
          switchMap((linkChannel) => {
            this.logger.debug('handleGridCellClick: set linked instrument', { widget, data, linkChannel });
            return this.linkChannelService.setLinkedInstrument$(widget, data).pipe(take(1));
          })
        )
        .subscribe(); // fire & forget
    }
  }
}
