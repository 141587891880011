<form class="wt-dialog wt-confirm-dialog" method="dialog">
  <div class="wt-dialog">
    <div class="wt-flex-row wt-flex-align-middle wt-flex-collapse wt-dialog__title">
      <div class="wt-flex-column wt-flex-shrink wt-dialog-icon wt-confirm-dialog__icon">
        <ng-container *ngIf="data.icon">
          <mat-icon *ngIf="data.isSVGIcon" class="mat-icon" svgIcon="{{ data.icon }}"></mat-icon>
          <mat-icon *ngIf="!data.isSVGIcon" class="mat-icon" fontIcon="{{ data.icon }}"></mat-icon>
        </ng-container>
        <mat-icon *ngIf="!data.icon" class="mat-icon" svgIcon="delete"></mat-icon>
      </div>

      <div class="wt-flex-column">
        <h1 class="wt-h1">{{ data.header | translate }}</h1>
      </div>
    </div>

    <p class="wt-dialog__message">{{data.message | translate}}</p>

    <p>
      <mat-checkbox *ngIf="this.data.disablePromptSettingKey ?? true" [(ngModel)]="disablePrompt" name="do-not-ask-again">
        {{ "CONFIRMATION_PROMPT.DO_NOT_ASK_AGAIN" | translate }}
      </mat-checkbox>
    </p>

    <div class="wt-action-buttons" *ngIf="!data.noCancelButton">
      <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
        <div class="wt-flex-column wt-flex-shrink">
          <button class="wt-button wt-button--cancel" type="button" (click)="onClose(false)"
            data-testid="confirmation-prompt-cancel">
            {{ data.cancelButtonLabel ?? ("GLOBAL.BUTTON.CANCEL" | translate) }}
          </button>
        </div>

        <div class="wt-flex-column wt-flex-shrink">
          <button class="wt-button wt-button--primary" type="submit" (click)="onClose(true)"
            data-testid="confirmation-prompt-ok">
            {{ data.okButtonLabel ?? ("GLOBAL.BUTTON.OK" | translate) }}
          </button>
        </div>
      </div>
    </div>

    <div class="wt-action-buttons" *ngIf="data.noCancelButton">
      <div class="wt-flex-row wt-flex-collapse wt-flex-align-right">
        <div class="wt-flex-column wt-flex-shrink">
          <button class="wt-button wt-button--primary" type="submit" (click)="onClose(true)"
            data-testid="confirmation-prompt-ok">
            {{ data.okButtonLabel ?? ("GLOBAL.BUTTON.OK" | translate) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>