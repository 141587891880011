import { InfrontSDK } from '@infront/sdk';

import type { DashboardTemplates } from './templates.model';


import { chartDashboardWidgets, chartDashboardWindows } from './chart-template';
import {
  overviewDashboardGrids as etfOverviewDashboardGrids,
  overviewDashboardWidgets as etfOverviewDashboardWidgets,
  overviewDashboardWindows as etfOverviewDashboardWindows,
} from './etf-template';
import {
  overviewDashboardGrids as forexOverviewDashboardGrids,
  overviewDashboardWidgets as forexOverviewDashboardWidgets,
  overviewDashboardWindows as forexOverviewDashboardWindows,
} from './forex-template';
import {
  overviewDashboardGrids as fundOverviewDashboardGrids,
  overviewDashboardWidgets as fundOverviewDashboardWidgets,
  overviewDashboardWindows as fundOverviewDashboardWindows,
} from './fund-template';
import {
  overviewDashboardGrids as futureOverviewDashboardGrids,
  overviewDashboardWidgets as futureOverviewDashboardWidgets,
  overviewDashboardWindows as futureOverviewDashboardWindows,
} from './future-template';
import {
  overviewDashboardGrids as indexOverviewDashboardGrids,
  overviewDashboardWidgets as indexOverviewDashboardWidgets,
  overviewDashboardWindows as indexOverviewDashboardWindows,
} from './index-template';
import {
  overviewDashboardGrids as stockOverviewDashboardGrids,
  overviewDashboardWidgets as stockOverviewDashboardWidgets,
  overviewDashboardWindows as stockOverviewDashboardWindows,
} from './stock-template';
import { deepFreeze } from '../../util/object';


export const dashboardTemplates: DashboardTemplates = deepFreeze({
  [InfrontSDK.SymbolClassification.Bond]: {
    overviewDashboardWindows: forexOverviewDashboardWindows,
    overviewDashboardWidgets: forexOverviewDashboardWidgets,
    overviewDashboardGrids: forexOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Certificate]: {
    overviewDashboardWindows: futureOverviewDashboardWindows,
    overviewDashboardWidgets: futureOverviewDashboardWidgets,
    overviewDashboardGrids: futureOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Derivative]: {
    overviewDashboardWindows: futureOverviewDashboardWindows,
    overviewDashboardWidgets: futureOverviewDashboardWidgets,
    overviewDashboardGrids: futureOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.ETF]: {
    overviewDashboardWindows: etfOverviewDashboardWindows,
    overviewDashboardWidgets: etfOverviewDashboardWidgets,
    overviewDashboardGrids: etfOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Forex]: {
    overviewDashboardWindows: forexOverviewDashboardWindows,
    overviewDashboardWidgets: forexOverviewDashboardWidgets,
    overviewDashboardGrids: forexOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Fund]: {
    overviewDashboardWindows: fundOverviewDashboardWindows,
    overviewDashboardWidgets: fundOverviewDashboardWidgets,
    overviewDashboardGrids: fundOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Future]: {
    overviewDashboardWindows: futureOverviewDashboardWindows,
    overviewDashboardWidgets: futureOverviewDashboardWidgets,
    overviewDashboardGrids: futureOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Index]: {
    overviewDashboardWindows: indexOverviewDashboardWindows,
    overviewDashboardWidgets: indexOverviewDashboardWidgets,
    overviewDashboardGrids: indexOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
  [InfrontSDK.SymbolClassification.Stock]: {
    overviewDashboardWindows: stockOverviewDashboardWindows,
    overviewDashboardWidgets: stockOverviewDashboardWidgets,
    overviewDashboardGrids: stockOverviewDashboardGrids,
    chartDashboardWindows: chartDashboardWindows,
    chartDashboardWidgets: chartDashboardWidgets,
  },
});
