import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, type OnChanges, type SimpleChanges } from '@angular/core';
import { type BigNumberFormatOptions, FormattingService } from '@vwd/ngx-i18n';
import type { ICellRendererAngularComp } from 'ag-grid-angular';
import type { ICellRendererParams } from 'ag-grid-community';
import { lastIndexOfAny } from '../../util/array';

const FORMAT_OPTIONS: { [decimals: number]: BigNumberFormatOptions | undefined } = [
  { pattern: 'short', useGrouping: true, maximumFractionDigits: 0 },
  undefined,
  { pattern: 'short', useGrouping: true, maximumFractionDigits: 2 },
];

function explicitDecimalsOptions(decimals: number): BigNumberFormatOptions {
  return { pattern: 'short', useGrouping: true, minimumFractionDigits: decimals, maximumFractionDigits: decimals };
}

@Component({
  selector: 'wt-big-number',
  template: `
    <span class="{{numberClass}}">{{ number }}</span><span *ngIf="suffix" class={{suffixClass}}>{{ suffix }}</span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BigNumberComponent implements OnChanges, ICellRendererAngularComp {
  @Input()
  value: string | number | undefined | null;

  @Input()
  decimals: number | undefined;

  @Input()
  defaultValue: string | undefined;

  numberClass = 'wt-big-number--number';
  suffixClass = 'wt-big-number--suffix';

  @Input()
  params?: ICellRendererParams & { classGetter?: (params: ICellRendererParams) => string };


  private valueFormatted?: unknown;

  number: string;
  suffix?: string;

  constructor(private format: FormattingService, private cdRef: ChangeDetectorRef) {
    this.number = format.nullText;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.setDisplayValue();
      this.setClasses();
    }
  }

  private setClasses() {
    if (this.params?.classGetter) {
      this.numberClass = this.params.classGetter(this.params ?? this.value) || 'wt-big-number--number';
      const suffixClass = this.params.classGetter(this.params ?? this.value);
      this.suffixClass = suffixClass ? `${suffixClass} wt-big-number--suffix--faded` : 'wt-big-number--suffix';
    }
  }

  agInit(params: ICellRendererParams): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.value = params.valueFormatted ?? params.value;
    this.params = params;
    this.setClasses();
    this.setDisplayValue();
  }

  refresh(params: ICellRendererParams): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.value = params.valueFormatted ?? params.value;
    if (this.setDisplayValue()) {
      this.cdRef.detectChanges();
    }
    return true;
  }

  private setDisplayValue(): boolean {
    let value = this.value;
    if (typeof value === 'number') {
      if (isNaN(value)) {
        value = this.defaultValue ?? '';
      } else if (this.decimals != undefined) {
        value = this.format.formatBigNumber(+value, explicitDecimalsOptions(this.decimals));
      } else {
        value = this.format.formatBigNumber(+value, BigNumberComponent.formatOptions(value));
      }
    } else if (value == undefined) {
      value = this.defaultValue ?? '';
    }

    // now, typeof value === 'string'
    if (this.valueFormatted !== value) {
      if (value) {
        // split a "big number" in the number part and the suffix
        const endOfNumberIndex = lastIndexOfAny(value, '0123456789');
        if (endOfNumberIndex !== -1 && endOfNumberIndex !== value.length) {
          const number = value.substring(0, endOfNumberIndex + 1);
          const suffix = value.substring(endOfNumberIndex + 1);

          this.valueFormatted = value;
          this.number = number;
          this.suffix = suffix;
          return true;
        }
      }

      this.valueFormatted = value;
      this.number = value || this.format.nullText;
      this.suffix = undefined;
      return true;
    }

    return false;
  }

  static formatOptions(value: number): BigNumberFormatOptions {
    const absValue = Math.abs(value);
    const maximumFractionDigits = (absValue >= 1_000_000 && absValue < 100_000_000 ? 2 : 0);
    return FORMAT_OPTIONS[maximumFractionDigits] ?? { pattern: 'short', useGrouping: true, maximumFractionDigits };
  }
}
