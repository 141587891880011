import { DashboardFolderLevel, type DashboardFolderRef, type DashboardRef, DashboardType, KnownDashboardFolderIDs, isDashboardFolderRef, isDashboardItemRef } from '@infront/ngx-dashboards-fx';
import { PORTFOLIO_FOLDER_ID } from '../dashboard/providers/portfolio-dashboards';
import { INSTRUMENT_DASHBOARD_FOLDER_ID } from '../dashboard/providers/instrument-dashboard-template';
import { EMPTY_DEFAULT_DASHBOARD_ID } from '../dashboard/default-dashboards.service';

export function getDashboardIcon(dashboardRef: DashboardRef, context: 'new-dashboard-menu' | 'picker' | 'tabs'): string {
  if (dashboardRef.model.id === EMPTY_DEFAULT_DASHBOARD_ID) {
    return 'person';
  }

  if (isDashboardItemRef(dashboardRef) && dashboardRef.isLink) {
    const link = dashboardRef.link;
    if (link) {
      return getDashboardIcon(link, context);
    }
  }

  if (dashboardRef.model.parentId === PORTFOLIO_FOLDER_ID) {
    return 'account_balance';
  }

  if (dashboardRef.model.parentId === INSTRUMENT_DASHBOARD_FOLDER_ID) {
    return 'insert_chart_outlined';
  }

  if (context === 'new-dashboard-menu' && dashboardRef.model.parentId === KnownDashboardFolderIDs.ROOT) {
    return 'person';
  }

  switch (dashboardRef.model.level as DashboardFolderLevel) {
    case DashboardFolderLevel.GLOBAL:
      return 'language';
    case DashboardFolderLevel.COMPANY:
    case DashboardFolderLevel.SUBTENANT:
      return 'business';
    case DashboardFolderLevel.SHARED:
      return 'co_present';
    case DashboardFolderLevel.PERSONAL:
      return 'person';
    default:
      if (isDashboardFolderRef(dashboardRef)) {
        if (dashboardRef.childrenLoadState === 'error') {
          return 'error_outline';
        }
        if (dashboardRef.model.id === (KnownDashboardFolderIDs.GLOBAL as string)
          || dashboardRef.model.parentId === KnownDashboardFolderIDs.ROOT && dashboardRef.model.id !== (KnownDashboardFolderIDs.PERSONAL as string)
          || dashboardRef.model.parentId == undefined) {
          return 'settings';
        }
      }
      return 'tab';
  }
}

export function getDashboardChildIcon(folderRef: DashboardFolderRef, type: DashboardType) {
  return getDashboardIconForLevel(folderRef.model.level, type);
}

export function getDashboardIconForLevel(level: DashboardFolderLevel, type: DashboardType = DashboardType.DASHBOARD) {
  switch (level) {
    case DashboardFolderLevel.GLOBAL:
      return 'language';
    case DashboardFolderLevel.COMPANY:
    case DashboardFolderLevel.SUBTENANT:
      return 'business';
    case DashboardFolderLevel.SHARED:
      return 'co_present';
    case DashboardFolderLevel.PERSONAL:
      return 'person';
    default:
      return type === DashboardType.FOLDER ? 'folder' : 'tab';
  }
}
