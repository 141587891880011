/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, inject, Input, isDevMode, type OnDestroy, type OnInit, type StaticProvider, Type } from '@angular/core';
import { LogService } from '@vwd/ngx-logging';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { auditTime, distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { StoreService } from '../services/store.service';
import { NestedProgressService, ProgressService } from '../shared/progress';
import { type Widget, WidgetInstanceRef, type WidgetName } from '../state-model/widget.model';
import { type DashboardWindow, DefaultHeaderForLockedDashboards, GenericHeaders, InstrumentSearchableWindowNames, type PartialDashboardWindow, PortfolioDashboardWindows, WindowHeadersMap } from '../state-model/window.model';
import { DestroyRef } from '../util/destroy-ref';
import { DashboardWindowService, type TabsInfo } from './dashboard-window.service';
import { LinkChannelService } from './ui/link-channel-menu/link-channel.service';
import type { Dashboard } from '../state-model/dashboard.model';

@Component({
  selector: 'wt-dashboard-window',
  templateUrl: './dashboard-window.component.html',
  providers: [ProgressService, DestroyRef],
})
export class DashboardWindowComponent implements OnInit, OnDestroy {
  private readonly logger = inject(LogService).openLogger('dashboard-window');
  private readonly storeService = inject(StoreService);
  private readonly linkChannelService = inject(LinkChannelService);
  private readonly dashboardWindowService = inject(DashboardWindowService);
  private readonly progress = inject(ProgressService);

  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: DashboardWindow;
  vm$!: Observable<TabsInfo & { providers: StaticProvider[] }>;
  windowLifespanComponent$!: Observable<Type<any>>;
  filterWidgetToTab$!: Observable<{ widget: Widget, providers: StaticProvider[] }>;
  readonly linkChannelHover$ = this.linkChannelService.linkChannelHover$;
  isDev = isDevMode();
  readonly instrumentSearchableWindowNames = InstrumentSearchableWindowNames;
  readonly PortfolioDashboardWindows = PortfolioDashboardWindows;

  readonly GenericHeaders = GenericHeaders;
  headerType = '';

  readonly inProgress$ = this.progress.inProgress$.pipe(auditTime(100), distinctUntilChanged());

  @HostListener('updateDashboardWindow', ['$event']) onUpdate(partialWindow: PartialDashboardWindow) {
    this.logger.info('updateDashboardWindow', partialWindow);
    this.storeService.updateWindow(this.window, partialWindow);
  }

  // we keep track of loaded window lifespan components
  // they should be lazy loaded first time a tab is viewed and after that continue to be present in the tab, to not reload them over again we need to keep track of if they're loaded
  private readonly alreadyLoadedTabs: string[] = [];

  private readonly selectedTabAction = new Subject<string>();

  private servicesMap = new Map<string, { instanceRef: WidgetInstanceRef, providers: StaticProvider[] }>();

  defaultSelectedIndex: number | undefined;

  ngOnDestroy(): void {
    this.selectedTabAction.complete();
  }

  isTabViewed(tab: string, widget: Widget, component: Type<any>): boolean {
    if (this.alreadyLoadedTabs.includes(tab)) {
      return true;
    }
    if (tab === widget.name && widget.lifespan === 'window') {
      this.alreadyLoadedTabs.push(tab);
      this.windowLifespanComponent$ = of(component).pipe(take(1));
      return true;
    }
    return false;
  }

  onDelete(): void {
    this.storeService.deleteWindow(this.window);
  }

  ngOnInit(): void {
    this.vm$ = this.dashboardWindowService.tabsInfo$(this.window).pipe(
      map((tabsInfo: TabsInfo) => {
        // must shift to the next zone run, else Google (Material) will provide us with the wrong selectedWidgetName
        // which results in name-id ping-pong and the widget tabs selection will flip around!
        setTimeout(() => {
          const tabIndex = tabsInfo.tabs.findIndex((tab) => tab === this.window.selectedWidgetName);
          this.defaultSelectedIndex = tabIndex > -1 ? tabIndex : 0;
        }, 0);

        const services = this.trackWidgetInstance(tabsInfo.widget);
        return { ...tabsInfo, providers: services.providers };
      })
    );

    // only get widget state updates relevant to the current tab - this was not needed before window lifespan widget who now is present in non selected tabs
    // NOTE: we do not "combine" vm$ any more, to reduce multiple subscriptions and streaming emits!
    this.filterWidgetToTab$ = combineLatest([this.dashboardWindowService.tabsInfo$(this.window), this.selectedTabAction]).pipe(
      filter(
        ([vm, selectedTab]) => vm.widget.name === selectedTab && vm.widget.lifespan === 'window' && !this.alreadyLoadedTabs.includes(vm.widget.name)
      ),
      map(([vm]) => {
        const services = this.trackWidgetInstance(vm.widget);
        return { widget: vm.widget, providers: services.providers };
      }),
    );

    const isDragEnabled = this.window.dragEnabled !== false;
    const windowName = this.window.name;
    const defaultHeader = WindowHeadersMap[windowName]?.default;
    const lockedHeader = WindowHeadersMap[windowName]?.locked ?? DefaultHeaderForLockedDashboards;
    const headerTypeByName = this.headerTypeByName(windowName);

    const headerType =
      (isDragEnabled ? defaultHeader : lockedHeader) ??
      defaultHeader ??
      headerTypeByName ??
      'none';

    this.headerType = headerType;
  }

  private trackWidgetInstance(widget: Widget) {
    let services = this.servicesMap.get(widget.id);
    if (!services) {
      const instanceRef = new WidgetInstanceRef(widget);
      services = {
        instanceRef,
        providers: [
          // DestroyRef is provided by wtLazyComp
          { provide: WidgetInstanceRef, useValue: instanceRef },
          { provide: ProgressService, useClass: NestedProgressService },
        ]
      };
      this.servicesMap.set(widget.id, services);
    } else {
      services.instanceRef.instance = widget;
    }
    return services;
  }

  onSelectedTabChange(tabs: string[], index: number): void {
    if ((index == undefined) || (tabs?.[index] == undefined)) {
      return;
    }
    this.storeService.updateWindow(this.window, { selectedWidgetName: tabs[index] as WidgetName });
    this.selectedTabAction.next(tabs[index]);
  }

  tabsCompareFn(index: number, item: string): string {
    return item;
  }

  private headerTypeByName(input: string): string {
    const prefix = input.replace(/Window$/, '');
    return prefix.charAt(0).toLowerCase() + prefix.slice(1);
  }
}
