import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
@Component({
  selector: 'wt-new-watchlist-dialog',
  templateUrl: './new-watchlist-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewWatchlistDialogComponent {
  providerReadAccess: { provider: number | undefined, providerName: string }[] = [
    { provider: undefined, providerName: 'User' },
  ];
  constructor(
    public dialogRef: MatDialogRef<NewWatchlistDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      input: { title: string, provider: number, providerReadAccess: { provider: number | undefined, providerName: string }[] },
    },
  ) {
    this.providerReadAccess = data.input.providerReadAccess;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
