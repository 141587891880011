<ng-container *ngIf="symbol() as symbol">
  <div class="wt-symbol-status" [hidden]="!showStatus()">
    <label class="wt-symbol-status__label"
      [ngClass]="'wt-symbol-status__label--' + DisplayStatusMap[symbol.DisplaySymbolStatusCode]"
      title="{{ symbol.PreSymbolStatusText }}">
      {{ ('SYMBOL_STATUS_COMPONENT.LABEL.' + symbol.DisplaySymbolStatus) | translate }}
    </label>
  </div>

  <label [hidden]="showStatus()">{{ alternativeValue() }}</label>
</ng-container>