<div class="wt-orderbook" *ngIf="orderbook$ | async as orderbook">
  <ng-container *ngIf="symbolInfo$ | async as symbol">
    <div #container>
      <div class="wt-orderbook__topbar" *ngIf="settings.showTopBar">
        <div class="wt-flex-row wt-flex-collapse wt-flex-align-middle wt-flex-align-justify">
          <div class="wt-flex-column wt-flex-shrink">
            <div class="wt-orderbook__topbar-symbol-indicators" #topBarLeftSide>
              <wt-symbol-status *ngIf="instrument$ | async as instrument" [instrument]="instrument"></wt-symbol-status>

              <wt-symbol-events *ngIf="instrument$ | async as instrument" [instrument]="instrument"></wt-symbol-events>
            </div>
          </div>

          <div class="wt-flex-column wt-flex-shrink">
            <div class="wt-orderbook__topbar-symbol"
              *ngIf="numberOfHiddenTopBarElements$ | async as numberOfHiddenTopBarElements">
              <span class="wt-orderbook__topbar-symbol-info" #topBarRightSideElm
                *ngIf="numberOfHiddenTopBarElements.value < 1">
                <span [ngClass]="!!symbol.isHighlightTurnover ? 'wt-field-label grid__cell--highlight' : 'wt-field-label'">
                  {{ 'ORDERBOOK_VIEW_COMPONENT.TOP.TURNOVER' | translate }}
                </span>
                <wt-big-number [value]="$any(symbol.Turnover)" [decimals]="3" [params]="$any({classGetter: highlightByADVpercent, data: symbol })"></wt-big-number>
              </span>

              <span class="wt-orderbook__topbar-symbol-wap" #topBarRightSideElm
                *ngIf="numberOfHiddenTopBarElements.value < 2">
                <span class="wt-field-label">
                  {{ 'ORDERBOOK_VIEW_COMPONENT.TOP.VWAP' | translate }}
                </span>

                {{ symbol.VWAP != undefined ? (symbol.VWAP | number: '1.3') : '-' }}
              </span>

              <span class="wt-orderbook__topbar-symbol-last" #topBarRightSideElm
                *ngIf="numberOfHiddenTopBarElements.value < 3">
                <span class="wt-field-label">
                  {{ 'ORDERBOOK_VIEW_COMPONENT.TOP.LAST' | translate }}
                </span>

                {{ symbol.PreLastTradedAt | price:{ decimals: orderbook.decimals } }}
              </span>

              <span class="wt-orderbook__topbar-symbol-change" #topBarRightSideElm>
                <span class="wt-field-label">
                  {{ 'ORDERBOOK_VIEW_COMPONENT.TOP.CHANGE' | translate }}
                </span>

                <span [style.color]="symbol.Change | numberToColor">{{ symbol.Change | price:{ decimals:
                  orderbook.decimals } }}</span>
              </span>

              <span *ngIf="symbol.ChangePercent" class="wt-orderbook__topbar-symbol-pct-change" #topBarRightSideElm>
                <span [style.color]="symbol.ChangePercent | numberToColor">
                  {{ symbol.ChangePercent | pctChange }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wt-orderbook__content gridster-item-content"
      [ngClass]="{ 'wt-orderbook__content--show-top-bar': settings.showTopBar }">
      <table>
        <!-- cellspacing="0" cellpadding="0" -->
        <colgroup>
          <col class="wt-orderbook__content__order-count-col" *ngIf="settings.showOrderAmount" />
          <col class="wt-orderbook__content__size-col" />
          <col class="wt-orderbook__content__price-col" />
          <col class="wt-orderbook__content__price-col" />
          <col class="wt-orderbook__content__size-col" />
          <col class="wt-orderbook__content__order-count-col" *ngIf="settings.showOrderAmount" />
        </colgroup>
        <tr>
          <th class="wt-orderbook__content__orders-count-header-left" *ngIf="settings.showOrderAmount">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.COUNT' | translate }}
          </th>
          <th class="wt-orderbook__content__volume-header-left">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.BID_SIZE' | translate }}
          </th>
          <th class="wt-orderbook__content__price-header-left">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.BID' | translate }}
          </th>
          <th class="wt-orderbook__content__price-header-right">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.ASK' | translate }}
          </th>
          <th class="wt-orderbook__content__volume-header-right">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.ASK_SIZE' | translate }}
          </th>
          <th class="wt-orderbook__content__orders-count-header-right" *ngIf="settings.showOrderAmount">
            {{ 'ORDERBOOK_VIEW_COMPONENT.CONTENT.COUNT' | translate }}
          </th>
        </tr>

        <tr
          *ngFor="let level of orderbook.levels; let i = index; trackBy: levelsCompareFn"
          class="wt-orderbook__content__top-level"
          [ngClass]="{
              'wt-orderbook__content__top-level--yellow': i === 0 && settings.yellowTopLevel,
              'wt-orderbook__content__top-level--bold': i === 0 && settings.boldTopLevel
            }">
          <td class="wt-orderbook__content__orders-count-left" *ngIf="settings.showOrderAmount">{{
            level.bidLevel?.ordersCount }}</td>
          <td class="wt-orderbook__content__volume-left">
            <div
              class="wt-orderbook__content__progress-bar wt-orderbook__content__progress-bar--bid"
              [ngClass]="{ 'wt-orderbook__content__progress-bar--auction': i === 0 && symbol.DisplaySymbolStatusCode === 5 }"
              [style.width]="orderbook.highestVolume ? (((level.bidLevel?.volume ?? 0)/ orderbook.highestVolume) * 100) + '%' : '0'">
            </div>
            <div class="wt-orderbook__content__bid-ask-label">{{ level.bidLevel?.volume }}</div>
          </td>
          <ng-container *ngIf="{ isConnected: tradingConnected$ | async } as tradingConnectedObj">
            <td class="wt-orderbook__content__price-left"
              [ngClass]="{'wt-orderbook--clickable': tradingConnectedObj.isConnected }"
              (click)="tradingConnectedObj.isConnected ? openOrderEntry(level.bidLevel?.price) : undefined">
              {{ $any(level.bidLevel?.price) | price:{ decimals: orderbook.decimals } }}
            </td>
            <td class="wt-orderbook__content__price-right"
              [ngClass]="{'wt-orderbook--clickable': tradingConnectedObj.isConnected }"
              (click)="tradingConnectedObj.isConnected ? openOrderEntry(level.askLevel?.price) : undefined">
              {{ $any(level.askLevel?.price) | price:{ decimals: orderbook.decimals } }}
            </td>
          </ng-container>
          <td class="wt-orderbook__content__volume-right">
            <div
              class="wt-orderbook__content__progress-bar wt-orderbook__content__progress-bar--ask"
              [ngClass]="{ 'wt-orderbook__content__progress-bar--auction': i === 0 && symbol.DisplaySymbolStatusCode === 5 }"
              [style.width]="orderbook.highestVolume ? (((level.askLevel?.volume ?? 0)/ orderbook.highestVolume) * 100) + '%' : '0'">
            </div>
            <div class="wt-orderbook__content__bid-ask-label">{{ level.askLevel?.volume }}</div>
          </td>
          <td class="wt-orderbook__content__orders-count-right" *ngIf="settings.showOrderAmount">{{
            level.askLevel?.ordersCount }}</td>
        </tr>
      </table>
    </div>
    <div class="wt-orderbook__bottombar">
      <div class="wt-flex-row wt-flex-collapse wt-flex-align-justify">
        <div class="wt-flex-column wt-flex-shrink orderbook__bottombar-bid-ask">
          <div class="wt-orderbook__bottombar-bid-ask-region"
            [title]="'ORDERBOOK_VIEW_COMPONENT.BOTTOM.BID_AND_ASK' | translate">
            <!-- {{ orderbook.bidAskRatio }} -->
            <div class="wt-orderbook__bottombar-bid-bar-spread" [style.width]="orderbook.bidAskRatio + '%'">
              <span class="wt-orderbook__bottombar-bid-bar-spread-label" *ngIf="orderbook.bidAskRatio > 29">
                {{ orderbook.bidAskRatio | pctChange: { decimals: 0 } }}
              </span>
            </div>

            <div class="wt-orderbook__bottombar-ask-bar-spread" [style.width]="(100 - orderbook.bidAskRatio) + '%'">
              <span class=" wt-orderbook__bottombar-ask-bar-spread-label" *ngIf="100 - orderbook.bidAskRatio > 29">
                {{ (100 - orderbook.bidAskRatio) | pctChange: { decimals: 0 } }}
              </span>
            </div>
          </div>
        </div>

        <div class="wt-flex-column wt-flex-shrink">
          <span class="wt-orderbook__bottombar-spread" *ngIf="!hideBottomBarElement || isCompact">
            <span class="wt-field-label">
              {{ 'ORDERBOOK_VIEW_COMPONENT.BOTTOM.SPREAD' | translate }}
            </span>

            {{ orderbook.spread != undefined ? (orderbook.spread | pct) : '-' }}
          </span>

          <span class="wt-orderbook__bottombar-latest-trade" *ngIf="!isCompact">
            <span class="wt-field-label">
              {{ 'ORDERBOOK_VIEW_COMPONENT.BOTTOM.LATEST_TRADE' | translate }}
            </span>

            {{ symbol.PreDisplayTime | date: 'timeOrShortestDate' }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
