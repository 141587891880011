import { Injectable, inject } from '@angular/core';
import { Infront, InfrontSDK, InfrontUtil } from '@infront/sdk';
import { LogService, Logger } from '@vwd/ngx-logging';
import { EMPTY, NEVER, Observable, ReplaySubject, Subject, Subscriber, combineLatest, of } from 'rxjs';
import { exhaustMap, finalize, map, pairwise, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ProgressService, trackProgress } from '../shared/progress';
import { type CommonWatchlistWidgetType, type Widget, isCommonWatchlistWidget } from '../state-model/widget.model';
import { type CommonWatchlistWindowType, type DashboardWindow, isCommonWatchlistWindow, isInstrument } from '../state-model/window.model';
import { structuresAreEqual } from '../util/equality';
import { guard } from '../util/rxjs';
import type { UnionTypeFromObjectProperties } from '../util/types';
import type { WatchlistByProvider } from '../widgets/watchlist/watchlist.model';
import { SdkService } from './sdk.service';
import { StoreService } from './store.service';

export const GLOBAL_WL_TRANSLATE_KEYS: Readonly<{ [index: string]: string; }> = {
  DEFAULT_TITLE: 'GLOBAL.WATCHLIST.DEFAULT_TITLE',
};

// Can be used in watchlist SDK calls, to have a formatted error on production,
// but more error details on development system.
export const sdkOnError = (logger: Logger) => {
  return (e: InfrontSDK.ErrorBase) => {
    logger.error(e.title, e.parameters?.code, e.parameters?.msg);
    if (environment.development) {
      logger.error('Watchlist SDK Error', e);
      logger.error(Error().stack);
    }
  };
};

export interface WatchlistItemChanged {
  item: InfrontSDK.Watchlist;
  index: number;
}

export interface WatchlistsUpdate {
  watchlists: InfrontSDK.Watchlist[];
  watchlistChanged?: WatchlistItemChanged;
}

type CommonWatchlistWindowOrWidget = UnionTypeFromObjectProperties<{ window: CommonWatchlistWindowType; widget: CommonWatchlistWidgetType; }>;

export type WatchlistOrId = InfrontSDK.Watchlist | string | undefined;

@Injectable({
  providedIn: 'root',
})
export class WatchlistService {
  private readonly logger = inject(LogService).openLogger('services/watchlist');
  private readonly sdkService = inject(SdkService);
  private readonly storeService = inject(StoreService);

  readonly watchlists$ = this.sdkService.sdk$.pipe(
    switchMap((sdk) =>
      new Observable((subscriber: Subscriber<WatchlistsUpdate>) => {
        const watchlistOptions: InfrontSDK.WatchListsObservableArrayOptions = {
          provider: true,
          subscribe: true,
          onData: (data: Infront.ObservableArray<InfrontSDK.Watchlist>) => {
            data.observe({
              itemAdded: () => {
                this.logger.debug('Item Added', data.data);
                subscriber.next({ watchlists: data.data });
              },
              itemRemoved: () => {
                this.logger.debug('Item Removed', data.data);
                subscriber.next({ watchlists: data.data });
              },
              reInit: () => {
                this.logger.debug('Re-initialized', data.data);
                subscriber.next({ watchlists: data.data });
              },
              itemChanged: (item: InfrontSDK.Watchlist, index: number) => {
                this.logger.debug('Item Changed', { item, index });
                subscriber.next({
                  watchlists: data.data,
                  watchlistChanged: { item, index }
                });
              },
              itemMoved: () => {
                this.logger.debug('Item Moved', data.data);
                subscriber.next({ watchlists: data.data });
              },
            });
            // subscriber.next({ watchlists: data.data }); // Initial, but not really required since first should call reInit (?)
          },
          onError: (error: InfrontSDK.ErrorBase<{ validation: string[], options: InfrontSDK.DataRequestOptions; }>) => {
            this.logger.error('Error', error);
            throw new Error(`SDK error:${error.title} ${error.parameters?.validation?.[0]}`);
          },
        };
        this.logger.debug('Watchlist SDK Request', watchlistOptions);
        this.watchlistInfrontSDKUnsubscribe = sdk.get(InfrontSDK.Requests.watchListsAsObservableArray(watchlistOptions));
      })
    ),
    // KEEP DEEPCOPY, otherwise this will have huge sideeffects on the chained Observables
    map((watchlistsUpdate) => InfrontUtil.deepCopy(watchlistsUpdate) as WatchlistsUpdate),
    map((watchlistsUpdate) => {
      // catch falsey, failback empty array
      watchlistsUpdate.watchlists ??= [];
      return watchlistsUpdate;
    }),
    map((watchlistsUpdate: WatchlistsUpdate) => {
      // filter invalid symbols
      const watchlists = watchlistsUpdate?.watchlists;
      watchlists.forEach((wl) => {
        if (wl.items?.length) {
          wl.items = wl.items.filter((symbolId) => isInstrument(symbolId));
        }
      });
      if (watchlistsUpdate?.watchlistChanged) {
        watchlistsUpdate.watchlistChanged.item.items = watchlistsUpdate.watchlistChanged.item.items?.filter((symbolId) => isInstrument(symbolId));
      }
      this.watchlistTitlesAction.next(watchlistsUpdate.watchlists.map((wl) => wl.title));
      return watchlistsUpdate;
    }),
    finalize(() => this.watchlistInfrontSDKUnsubscribe?.()),
    shareReplay(1),
  );

  getWatchlists$(progress?: ProgressService) {
    return this.watchlists$.pipe(
      trackProgress({ label: 'watchlists$', progress, optional: true }),
    );
  }

  readonly watchlistsByProviders$ = this.watchlists$.pipe(
    map(({ watchlists }) => {
      return watchlists.reduce<WatchlistByProvider>((wlsByProvider, wl) => {
        const provider = wl.provider ?? 0;
        wlsByProvider[provider] ??= [];
        wlsByProvider[provider].push(wl);
        return wlsByProvider;
      }, {});
    }),
    shareReplay(1),
  );

  readonly watchlistTitlesAction = new ReplaySubject<string[]>(1);
  readonly watchlistTitles$ = this.watchlistTitlesAction.asObservable();

  private watchlistInfrontSDKUnsubscribe: InfrontSDK.Unsubscribe | undefined;

  readonly commonWatchlistWindowByWindow$ = (window: DashboardWindow) => this.storeService.window$(window).pipe(guard(isCommonWatchlistWindow));
  readonly commonWatchlistWindowByWidget$ = (widget: Widget) => this.storeService.windowByWidget$(widget).pipe(guard(isCommonWatchlistWindow));
  readonly commonWatchlistWidgetType$ = (widget: Widget) => this.storeService.widget$(widget).pipe(guard(isCommonWatchlistWidget));

  readonly selectedWatchlistInWidget$ = (widget$: Observable<CommonWatchlistWidgetType>, selectFallbackWatchlist = false, progress?: ProgressService) =>
    combineLatest([widget$, this.getWatchlists$(progress)]).pipe(
      map(([widget, { watchlists }]: [CommonWatchlistWidgetType, WatchlistsUpdate]) => {
        const selectedWatchlistId = widget.settings.selectedWatchlist;
        if (!selectedWatchlistId) {
          return undefined;
        }
        // Test if the corresponding watchlist still exists
        const selectedWatchlist: InfrontSDK.Watchlist | undefined = this.getWatchlist({ id: selectedWatchlistId }, watchlists, selectFallbackWatchlist);
        this.onSelectedWatchlistChange({ widget }, selectedWatchlist);
        return selectedWatchlist;
      }),
    );

  readonly selectedWatchlistInWindow$ = (window$: Observable<CommonWatchlistWindowType>, selectFallbackWatchlist = false, progress?: ProgressService) =>
    combineLatest([
      window$,
      this.getWatchlists$(progress)
    ]).pipe(
      map(([window, { watchlists }]: [CommonWatchlistWindowType, WatchlistsUpdate]) => {
        const selectedWatchlistId = window.settings.selectedWatchlist;
        if (!selectedWatchlistId) {
          return undefined;
        }
        // Test if the corresponding watchlist still exists
        const selectedWatchlist: InfrontSDK.Watchlist | undefined = this.getWatchlist({ id: selectedWatchlistId }, watchlists, selectFallbackWatchlist);
        this.onSelectedWatchlistChange({ window }, selectedWatchlist);
        return selectedWatchlist;
      }),
    );

  // @TODO rename in watchlist$
  readonly getWatchlistById$ = (id: string | undefined, progress?: ProgressService): Observable<InfrontSDK.Watchlist | undefined> => {
    if (!id) {
      return of(undefined);
    }
    return this.getWatchlists$(progress).pipe(map((watchlistUpdate) => watchlistUpdate?.watchlists?.find((wl) => wl.id === id)));
  };

  // @TODO IWT-1088 to be removed as subscribe by title has risk of desync, migrate to getWatchlistById$
  readonly getWatchlistByTitle$ = (title: string | undefined, progress?: ProgressService): Observable<InfrontSDK.Watchlist | undefined> => {
    if (!title) {
      return of(undefined);
    }
    return this.getWatchlists$(progress).pipe(map((watchlistUpdate) => watchlistUpdate?.watchlists?.find((wl) => wl.title === title)));
  };

  readonly watchlistSymbolIds$ = map((watchlist: InfrontSDK.Watchlist | undefined) => watchlist
    ? watchlist.items || [] // Not sure if fallback is still necessary
    : []
  );

  getWatchlist(
    idOrTitle: UnionTypeFromObjectProperties<{ id: InfrontSDK.Watchlist['id']; title: InfrontSDK.Watchlist['title']; }>,
    watchlists: InfrontSDK.Watchlist[],
    selectFallbackWatchlist = false,
    progress?: ProgressService,
  ): InfrontSDK.Watchlist | undefined {
    if (!watchlists.length) {
      return undefined;
    }

    const [key, value] = Object.entries(idOrTitle ?? {})[0] ?? [undefined, undefined];
    if (!key || !value) {
      return undefined;
    }
    const findPredicate = (wl: InfrontSDK.Watchlist) => wl[key as keyof InfrontSDK.Watchlist] === value;

    return watchlists.find(wl => findPredicate(wl)) || (selectFallbackWatchlist ? watchlists[0] : undefined);
  }

  /**
   * Subscribe to changes with your selectedWatchlist$
   * Expects a Subject like Observable (requires "next" function to be able to call itself recursively)
   * @param selectedWatchlist$ The rxjs subject that holds the selectedWatchlist value
   * @param selectFirstWatchlistOnUndefInit
   * @param selectFirstWatchlistOnUndef
   * @returns an Observable containing the selectedWatchlist
   */
  readonly getSelectedWatchlist$ = (
    selectedWatchlist$: Observable<string | undefined> & { next: (val: string | undefined) => void; },
    selectFirstWatchlistOnUndefined: boolean = true,
    progress?: ProgressService
  ): Observable<InfrontSDK.Watchlist | undefined> =>
    combineLatest([
      this.getWatchlists$(progress).pipe(map(({ watchlists }) => watchlists)),
      selectedWatchlist$,
    ]).pipe(
      startWith([[], undefined] as [InfrontSDK.Watchlist[], string | undefined]),
      pairwise(),
      switchMap(([[oldWatchlists, oldSelectedWatchlist], [watchlists, selectedWatchlist]]) => {
        if (!watchlists?.length || (selectedWatchlist == undefined && !selectFirstWatchlistOnUndefined)) {
          return of(undefined);
        }
        const selectedWatchlistId = this.getWatchlistId(selectedWatchlist);
        if (selectedWatchlistId) {
          const selectedWatchlist = watchlists.find((wl) => wl.id === selectedWatchlistId);
          if (selectedWatchlist) {
            // Found
            const oldSelectedWatchlistId = this.getWatchlistId(oldSelectedWatchlist);
            if (selectedWatchlistId === oldSelectedWatchlistId) {
              const oldSelectedWatchlist = oldWatchlists.find((wl) => wl.id === selectedWatchlistId);
              if (!structuresAreEqual(selectedWatchlist, oldSelectedWatchlist)) {
                // Only emit when there are real changes to the watchlist
                return of(selectedWatchlist);
              }
              return NEVER;
            }
            return of(selectedWatchlist);
          } else {
            return NEVER;
          }
        }
        if (selectFirstWatchlistOnUndefined && watchlists[0]) {
          selectedWatchlist$.next(watchlists[0].id);
          return NEVER;
        }
        // Not found -> results in immediate of(undefined) when selectedWatchlist$.next(undefined) is fired
        selectedWatchlist$.next(undefined);
        return NEVER;
      }),
    );

  readonly saveWatchlist$ = (title: string, provider?: number, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[] = []): Observable<boolean> => {
    if (!title) {
      return of(false);
    }
    const saveWatchlistContentOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.SaveWatchList,
      listName: title,
      provider,
      symbolId: typeof symbolId === 'object'
        ? Array.isArray(symbolId) // NOSONAR is good to read as it is!
          ? symbolId
          : [symbolId] // single symbolId, non array, needs to be casted
        : [],
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, saveWatchlistContentOptions);
  };

  readonly deleteWatchlist$ = (title: string, provider?: number): Observable<boolean> => {
    if (!title) {
      return of(false);
    }
    const deleteWatchlistOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'symbolId' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.DeleteWatchList,
      listName: title,
    };
    if (provider) {
      deleteWatchlistOptions.provider = provider;
    }
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, deleteWatchlistOptions);
  };

  readonly renameWatchlist$ = (title: string, newTitle: string, provider?: number): Observable<boolean> => {
    if (!title || !newTitle || title === newTitle) {
      return of(false);
    }
    const renameWatchlistOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'symbolId'> = {
      action: InfrontSDK.WatchListContentAction.RenameWatchList,
      listName: title,
      listNameUpdated: newTitle,
      provider
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, renameWatchlistOptions);
  };

  readonly updateWatchlistRequestAction$ = new Subject<{ title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number, addToWatchlist: boolean; }>();

  readonly updateWatchlist = (title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number, addToWatchlist: boolean = true) => this.updateWatchlistRequestAction$.next({ title, symbolId, addToWatchlist, provider });

  private readonly updateWatchlistSubscription = this.updateWatchlistRequestAction$.pipe(exhaustMap(params => {
    const { title, symbolId, addToWatchlist, provider } = params;
    if (!title || !symbolId) {
      return EMPTY;
    }
    return (addToWatchlist ? this.addSymbolIdToWatchlist(title, symbolId, provider, sdkOnError(this.logger)) : this.removeSymbolIdFromWatchlist(title, symbolId, provider)).pipe(take(1));
  })).subscribe();


  readonly addSymbolIdToWatchlist = (title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number, onError = (error: InfrontSDK.ErrorBase) => { }): Observable<boolean> => {
    if (!title || !symbolId) {
      return of(false);
    }
    const addSymbolIdToWlOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.AddSymbolId,
      listName: title,
      symbolId,
      provider,
      onError,
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, addSymbolIdToWlOptions);
  };

  // @FIXME: can't remove last instrument in wl, causes error blocks websockets
  readonly removeSymbolIdFromWatchlist = (title: string, symbolId: InfrontSDK.SymbolId | InfrontSDK.SymbolId[], provider?: number): Observable<boolean> => {
    if (!title || !symbolId) {
      return of(false);
    }
    const deleteSymbolIdFromWlOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'listNameUpdated'> = {
      action: InfrontSDK.WatchListContentAction.RemoveSymbolId,
      listName: title,
      symbolId,
      provider
    };
    return this.sdkService.getObject$(InfrontSDK.Requests.watchListContent, deleteSymbolIdFromWlOptions);
  };

  // @FIXME in SDK - requires some kind of indication when all instruments have been received
  // getWatchlistContent$ = (title: string): Observable<InfrontSDK.WatchlistItem[] | undefined> => {
  //   if (!title) {
  //     return of(undefined);
  //   }
  //   const getWatchlistContentOptions: Omit<InfrontSDK.WatchListContentOptions, 'onData' | 'symbolId'> = {
  //     action: InfrontSDK.WatchListContentAction.LoadWatchList,
  //     listName: title,
  //   };
  //   return this.sdkService.getArray$(InfrontSDK.Requests.watchListContent, getWatchlistContentOptions).pipe(
  //     map((watchListContent) =>
  //       watchListContent?.filter((items) => items.feed && items.ticker)
  //     )
  //   )
  // };

  onSelectedWatchlistChange(
    windowOrWidget: CommonWatchlistWindowOrWidget,
    selectedWatchlist: InfrontSDK.Watchlist | undefined,
  ): void {
    if (this.selectedWatchlistHasChanged(windowOrWidget, selectedWatchlist?.id)) {
      if ('window' in windowOrWidget && windowOrWidget.window) {
        const window = windowOrWidget.window;
        this.storeService.updateWindow(window, { ...window, settings: { ...window.settings, selectedWatchlist: selectedWatchlist?.id } });
      } else if ('widget' in windowOrWidget && windowOrWidget.widget) {
        const widget = windowOrWidget.widget;
        this.storeService.updateWidget(widget, { ...widget, settings: { ...widget.settings, selectedWatchlist: selectedWatchlist?.id } });
      }
    }
  }

  selectedWatchlistHasChanged(
    windowOrWidget: CommonWatchlistWindowOrWidget,
    selectedWatchlistId: string | undefined,
  ): boolean {
    const prevSelectedWatchlist = (Object.values(windowOrWidget)[0] as CommonWatchlistWindowType | CommonWatchlistWidgetType).settings.selectedWatchlist;
    return prevSelectedWatchlist !== selectedWatchlistId;
  }

  getWatchlistTitle(watchlistOrTitle: WatchlistOrId): string | undefined {
    return typeof watchlistOrTitle === 'object' ? watchlistOrTitle.title : watchlistOrTitle;
  }

  getWatchlistId(watchlistOrId: WatchlistOrId): string | undefined {
    return typeof watchlistOrId === 'object' ? watchlistOrId.id : watchlistOrId;
  }

  readonly watchlistProviderAccess$ = this.sdkService.getObject$(InfrontSDK.watchlistProviderAccess).pipe(
    tap((accessData) => this.logger.info('Access Data', accessData)),
    shareReplay(1));

  readonly providerAccess$ = (provider: number, accessType: 'read' | 'write') => {
    return this.watchlistProviderAccess$.pipe(
      map(accessData => {
        const access = accessType === 'write' ? provider in accessData.write : provider in accessData.read;
        return access;
      }),
      take(1),
    );
  };
  // Determines if the watchlist should be disabled for write operations. Returns true if no write access and is a provider watchlist, otherwise false. Used for disabling in context menu.
  readonly shouldDisableWriteAccessForWatchlist$ = (watchlist: InfrontSDK.Watchlist | undefined) => {
    if (watchlist?.provider == undefined) {
      return of(false);
    }
    return this.providerAccess$(watchlist.provider, 'write').pipe(map(value => !value));
  };

}
