import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Dashboard } from '../../../state-model/dashboard.model';
import type { DashboardWindow } from '../../../state-model/window.model';


@Component({
  selector: 'wt-selected-link-channel',
  template: `
  <div class="link-channel">
    <button type="button" class="wt-button wt-button--icon" *ngIf="['Channel 1', 'Channel 2', 'Channel 3', 'Channel 4', 'All', 'Unlink'].includes(window.linkChannel)">
      <span class="link-channel__num">{{ window.linkChannel | linkDisplay }}</span>

      <mat-icon *ngIf="window.linkChannel === 'Channel 1'" class="link-channel-icon link-channel--channel1-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngIf="window.linkChannel === 'Channel 2'" class="link-channel-icon link-channel--channel2-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngIf="window.linkChannel === 'Channel 3'" class="link-channel-icon link-channel--channel3-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngIf="window.linkChannel === 'Channel 4'" class="link-channel-icon link-channel--channel4-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngIf="window.linkChannel === 'All'" class="link-channel-icon link-channel--all-icon" fontIcon="link"></mat-icon>
      <mat-icon *ngIf="window.linkChannel === 'Unlink'" class="link-channel-icon" fontIcon="add_link"></mat-icon>
    </button>

    <ng-container *ngIf="!dashboard.locked && !['Channel 1', 'Channel 2', 'Channel 3', 'Channel 4', 'All', 'Unlink'].includes(window.linkChannel)">
      <button type="button" class="wt-button wt-button--icon">
        <mat-icon class="link-channel-icon" fontIcon="add_link"></mat-icon>
      </button>
    </ng-container>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedLinkChannelComponent {
  @Input({ required: true }) dashboard!: Dashboard;
  @Input({ required: true }) window!: DashboardWindow;
}
